import React, { useEffect } from "react";
import { useRoutes, useNavigate } from "react-router-dom";

import MapLayout from "layouts/MapLayout";
import MainLayout from "layouts/MainLayout";
import { eventRoutes } from "./events";
import { spottersRoutes } from "./spotter";
import StormMap from "pages/StormMap";
import Tweets from "pages/Tweets";
import Photos from "pages/Photos";
import About from "pages/About";
import Feedback from "pages/Feedback";
import Privacy from "pages/Privacy";
import Forecast from "pages/Forecast";
import ForecastText from "pages/ForecastText";
import Settings from "pages/auth/Settings";
import Verify from "pages/auth/Verify";
import Register from "pages/auth/Register";
import ForgotPassword from "pages/auth/Forgot";
import ResetPassword from "pages/auth/Reset";
import Login from "pages/auth/Login";
import NotFound from "pages/NotFound";


export default function Routes() {
  const navigate = useNavigate();

  // Check for routes that need redirection
  const checkForRedirection = () => {
    const currentPath = window.location.pathname;

    // Check if the current path matches /events or /events/:date
    if (currentPath.startsWith("/events")) {
      // Redirect to /history or /history/:date
      const newPath = currentPath.replace("/events", "/history");

      return newPath;
    }

    return null; // No redirection needed
  };

  // In your component
  useEffect(() => {
    const newPath = checkForRedirection();

    if (newPath) {
      console.log("Navigating to new path");
      navigate(newPath);
    }
  }, []); // Empty dependency array means this effect runs once, after the initial re

  return useRoutes([
    {
      element: <MapLayout />,
      children: [{ path: "/", element: <StormMap /> }],
    },
    {
      element: <MainLayout />,
      children: [
        { path: "/tweets", element: <Tweets /> },
        { path: "/photos", element: <Photos /> },
        { path: "/feedback", element: <Feedback /> },
        { path: "/about", element: <About /> },
        { path: "/privacy", element: <Privacy /> },
        { path: "/forecast", element: <Forecast /> },
        { path: "/forecast/text", element: <ForecastText /> },

        ...eventRoutes,
        ...spottersRoutes,
      ],
    },
    {
      element: <MainLayout />,
      children: [
        { path: "/login", element: <Login /> },
        { path: "/verify", element: <Verify /> },
        { path: "/register", element: <Register /> },
        { path: "/forgot", element: <ForgotPassword /> },
        { path: "/reset", element: <ResetPassword /> },
        { path: "/settings", element: <Settings /> },        
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
}
