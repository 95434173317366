import React from "react";
import { IconLayer } from '@deck.gl/layers';
import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons"; 

import {
    formatHailSize, 
    hailIconSize,
    getTornadoIconName,
    getTornadoIconSize,
    getEventIcon,  
  } from "utils/map-functions";
  import {
    timeSince,
    utcDateToReadable,
    getRelativeTimeFromDateTime,
  } from "utils/general-functions";

import sliderFilterDark from 'assets/images/icons/sliderfilter-dark.webp';


// SPC TORNADO REPORT
export const createTornadoReportsLayer = ({
    layer_name,  
    spcTornadoReports,
    tornadoReportsCheck,
    displayPopup,
    zoomToPositionAndTime,
    setModalType,
    mapSource = "map"
  }) => {
    return new IconLayer({
      id: layer_name,
      data: spcTornadoReports,
      pickable: true,
      iconMapping: {
        marker: {
          x: 0,
          y: 0,
          width: 200,
          height: 200,
          sizeUnits: "pixels",
          mask: false,
        },
      },
      getIcon: (d) => ({ url: getTornadoIconName(d, true), width: 50, height: 50 }),
      getSize: (d) => getTornadoIconSize(d.f_scale_enhanced),
      getPosition: (d) => d.position,
      getColor: d => {
        const [r, g, b] = [Math.sqrt(d.exits), 140, 0];
        const opacity = Math.max(d.sliderRelativeOpacity * 255, 121); // Convert to 0-255 range
        return [r, g, b, opacity];
      },      
      visible: tornadoReportsCheck,
      onClick: (d) => {      
        setModalType("TornadoReports");
        displayPopup(
          
          <div className={"text-black font-bold"}>
            <h3 className={"text-black font-bold"}>
              <img src={getTornadoIconName(d.object, true)} alt="tornado icon" className="w-5 h-5" /> Tornado report
            </h3>
          
            <div className="grid grid-cols-3 gap-1 text-base">
              <div className="text-left inline-block text-black font-bold col-span-1">Source</div>
              <div className="col-span-2">{d.object.data_source.toUpperCase().replace("SPOTTER", "Spotter Network")}</div>                
              <div className="text-left inline-block text-black font-bold col-span-1">Time (UTC/Zulu)</div>
              <div className="col-span-2">
                {utcDateToReadable(d.object.datetime_utc, 'zulu')} {mapSource !== 'event' ? `${timeSince(new Date(d.object.datetime_utc))} ago`: ""} {d.object.sunlight? `, during ${d.object.sunlight}` : ""}) 
                &nbsp; 
                <img 
                  src={sliderFilterDark} 
                  alt="zoom to position and time" className={'h-4 cursor-pointer'} 
                  onClick={() => zoomToPositionAndTime(d.object["latitude"], d.object["longitude"], getRelativeTimeFromDateTime(d.object.datetime_utc))} /> 
                   
              </div>
              <div className="text-left inline-block text-black font-bold col-span-1">Reporter comment</div>
              <div className="col-span-2">{d.object.comments}</div>

              <div className="text-left inline-block text-black font-bold col-span-1">Reporter</div>
              <div className="col-span-2">{d.object.reporter ?? "-"}</div>  

              <div className="text-left inline-block text-black font-bold col-span-1">EF Scale</div>
              <div className="col-span-2">{d.object.f_scale_enhanced ?? "-"}</div>

              <div className="text-left inline-block text-black font-bold col-span-1">Place</div>
              <div className="col-span-2"> {d.object.location ? d.object.location + "," : ""} {d.object.county}, {d.object.state}{" "}</div>         

              <div className="text-left inline-block text-black font-bold col-span-1">Coordinates</div>
              <div className="col-span-2">{d.object.latitude}, {d.object.longitude}</div>
              
              {mapSource !== 'event' &&
                <>
                  <Tooltip title="Programmatic calculation of spotters that have been nearby (and possibly seen the tornado) based on distance and time on Spotter Network pings." arrow placement="top-start">
                    <div className="text-left inline-block text-black font-bold col-span-1 cursor-pointer">Spotters nearby <FontAwesomeIcon icon={faQuestionCircle} className="text-[#012533] text-sm"/></div>
                  </Tooltip>

                  <div className="col-span-2">
                      {d.object.spotters_nearby  ? (
                        <ul>
                          {d.object.spotters_nearby.map((spotter, index) => (
                            <li key={index}>
                              <span className="text-bold">{spotter.first_name} {spotter.last_name} {spotter.twitter ? (
                                  <a href={`https://x.com/${spotter.twitter}`} target="_blank" rel="noopener noreferrer" className="text-black underline">
                                    (@{spotter.twitter})
                                  </a>
                                ) : null}</span>:  {Math.round(spotter.distance_difference_miles * 100) / 100 } miles away @   {spotter.time_difference_minutes !== 0 ? (
                                  `${Math.abs(Math.round(spotter.time_difference_minutes * 100) / 100)} minutes ${spotter.time_difference_minutes > 0 ? 'after' : 'before'}`
                                ) : (
                                  'Exactly on time'
                                )}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <span className='text-black text-base'>No known spotters nearby.</span>
                      )}
                  </div>
                </>              
              }

            </div>   
          </div>
        );
      },
    });
  };


  // HAIL REPORT LAYER
  export const createHailReportsLayer = ({
    layer_name,
    spcHailReports,
    hailReportsCheck,
    displayPopup,
    zoomToPositionAndTime,
    setModalType
  }) => {
    return new IconLayer({
      id: layer_name,
      data: spcHailReports,
      pickable: true,
      iconAtlas: getEventIcon('hail'),
      iconMapping: {
        marker: {
          x: 0,
          y: 0,
          width: 50,
          height: 50,
          sizeUnits: "pixels",
          mask: false,
        },
      },
      getIcon: (d) => "marker",
      getSize: (d) => hailIconSize(d.size),
      getPosition: (d) => d.position,
      getColor: d => {
        const [r, g, b] = [0, 0, 0];
        let opacity = 255;
        if (d.sliderRelativeOpacity) {
          opacity = Math.max(d.sliderRelativeOpacity * 255, 100);  // Convert to 0-255 range
        }         
        return [r, g, b, opacity];
      },
      visible: hailReportsCheck,
      onClick: (d) => {
        setModalType('HailReports');
        displayPopup(
          <div className={"text-black font-bold"}>
            {console.log(d.object)}
            <h3 className={"text-black font-bold"}>
              <img src={getEventIcon('hail')} alt="hail icon" className="w-5 h-5" /> Hail report
            </h3>
           
            <div className="grid grid-cols-3 gap-1 text-base">
              <div className="text-left inline-block text-black font-bold col-span-1">Source</div>
              <div className="col-span-2">{d.object.data_source.toUpperCase()}</div>               
              <div className="text-left inline-block text-black font-bold col-span-1">Time (UTC/Zulu)</div>
              <div className="col-span-2">
                {utcDateToReadable(d.object.datetime_utc, 'zulu')} ({timeSince(new Date(d.object.datetime_utc))} ago)
                <img
                  src={sliderFilterDark}
                  alt=""
                  className={'h-4 cursor-pointer'}
                  onClick={() =>
                    zoomToPositionAndTime(
                      d.object["latitude"],
                      d.object["longitude"],
                      getRelativeTimeFromDateTime(d.object["datetime_utc"])
                    )
                  }
                />
              </div>
              <div className="text-left inline-block text-black font-bold col-span-1">Reporter comment</div>
              <div className="col-span-2">{d.object.comments}</div>
              <div className="text-left inline-block text-black font-bold col-span-1">Hail size</div>
              <div className="col-span-2">{formatHailSize(d.object.size)}</div>
              <div className="text-left inline-block text-black font-bold col-span-1">Place</div>
              <div className="col-span-2">
                {d.object.location ?? ""}, {d.object.county ?? ""}, {d.object.state ?? ""}{" "}
              </div>
              <div className="text-left inline-block text-black font-bold col-span-1">Coordinates</div>
              <div className="col-span-2">{d.object.latitude}, {d.object.longitude}</div>
            </div>
          </div>
        );
      },
    });
  };


  // WIND REPORT LAYER
  export const createWindReportsLayer = ({
    layer_name,    
    spcWindReports,
    windReportsCheck,
    displayPopup,
    zoomToPositionAndTime,
    setModalType
  }) => {
    return new IconLayer({
      id: layer_name,
      data: spcWindReports,
      pickable: true,
      iconAtlas: getEventIcon('wind'), // Is this really needed?
      iconMapping: {
        marker: {
          x: 0,
          y: 0,
          width: 200,
          height: 200,
          sizeUnits: "pixels",
          mask: true,
        },
      },
      getIcon: (d) => "marker",
      sizeScale: 20,
      getPosition: (d) => d.position,
      getColor: d => {
        const [r, g, b] = [0, 0, 0];
        const opacity = Math.max(d.sliderRelativeOpacity * 255, 100);  // Convert to 0-255 range
        return [r, g, b, opacity];
      },
      visible: windReportsCheck,
      onClick: (d) => {
        setModalType('WindReports');
        displayPopup(
          <div className={"text-black"}>
            {console.log(d.object)}
            <h3 className={"text-black font-bold"}>
              <img src={getEventIcon('wind')} alt="wind icon" className="w-5 h-5" /> <span className={"text-black font-bold"}>Wind report</span>
            </h3>
            
            <div className="grid grid-cols-3 gap-1 text-base">
              <div className="text-left inline-block text-black font-bold col-span-1">Source</div>
              <div className="col-span-2">{d.object.data_source.toUpperCase()}</div> 
              <div className="text-left inline-block text-black font-bold col-span-1">Time (UTC/Zulu)</div>
              <div className="col-span-2">
                {utcDateToReadable(d.object.datetime_utc, 'zulu')} ({timeSince(new Date(d.object.datetime_utc))} ago)
                <img
                  src={sliderFilterDark}
                  alt=""
                  className={'h-4 cursor-pointer'}
                  onClick={() =>
                    zoomToPositionAndTime(
                      d.object["latitude"],
                      d.object["longitude"],
                      getRelativeTimeFromDateTime(d.object["datetime_utc"])
                    )
                  }
                />
              </div>
              <div className="text-left inline-block text-black font-bold col-span-1">Reporter comment</div>
              <div className="col-span-2">{d.object.comments}</div>
              <div className="text-left inline-block text-black font-bold col-span-1">Wind speed</div>
              <div className="col-span-2">{d.object.speed}</div>
              <div className="text-left inline-block text-black font-bold col-span-1">Place</div>
              <div className="col-span-2">
                {d.object.location ?? ""}, {d.object.county ?? ""}, {d.object.state ?? ""}{" "}
              </div>
              <div className="text-left inline-block text-black font-bold col-span-1">Coordinates</div>
              <div className="col-span-2">{d.object.latitude}, {d.object.longitude}</div>
            </div>
          </div>
        );
      },
    });
  };  