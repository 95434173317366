import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ClickAwayListener } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMap,
    faTable,
    faChartSimple,
    faCircleInfo,
    faMask,
    faLocation,
    faCar,
    faTornado,
    faLayerGroup,
    faArchive,
    faCommentAlt,
    faGear,
    faEye,
    faXmark,
    faVideoCamera
} from "@fortawesome/free-solid-svg-icons";
import useMediaQuery from "@mui/material/useMediaQuery";

// Images
import instaLogo from "assets/images/logos/insta-logo.webp";
import fbLogo from "assets/images/logos/fb-logo.webp";
import twitterLogo from "assets/images/logos/twitt-logo.webp";
import urlLink from "assets/images/icons/url-link.webp";

import ParentComponent from "components/left-sidebar/ParentComponent";
import ChildComponent from "components/left-sidebar/ChildComponent";
import CheckboxComponent from "components/left-sidebar/CheckboxComponent";
import { timeSince, calculateZuluTimeFromNow, calculateCDTTimeFromNow } from "utils/general-functions";
import { useAuth } from 'contexts/AuthContext';

//import {faTwitter} from "@fortawesome/free-brands-svg-icons";

import { StormContext } from "contexts/StormContext";

function LeftSidebar({ children, menuStatus, toggleMenuOpen, toggleBtnRef }) {
    //const { state } = useAuth();
    const location = useLocation();
    const { state, logout} = useAuth();
    const currentUrl = location.pathname;
    const navigate = useNavigate();
    const [checkBox, setCheckBox] = useState({
        spotters: "", spotterFavorite: "",
    });
    const [spotters, setSpotters] = useState("");
    //const [tweetPhotos, setTweetPhotos] = useState("");
    const [radar, setradar] = useState("");
    const [tornadoReports, setTornadoReports] = useState("");
    const [hailReports, setHailReports] = useState("");
    const [windReports, setWindReports] = useState("");
    const [otherReports, setOtherReports] = useState("");
    const [spotterReports, setSpotterReports] = useState("");
    const [warnings, setSpcWarnings] = useState("");
    const [watches, setWatches] = useState("");
    const [tvChannels, setTvChannels] = useState("");
    const [legend, setLegend] = useState("");
    const [chaserSetup, setChaserSetup] = useState("");
    const isMobile = useMediaQuery("(max-width:600px)");
    const handleClose = () => {
        toggleMenuOpen(); // Call the toggleMenuOpen function from the parent component
    };

    const onChangeHandler = (e) => {
        setOutlookDay(e.target.value);
        closeAllOpenSidebars()
    };

    const handleStyleChange = (event) => {
        console.log("handleStyleChange", event.target.value)
        console.log(selectableMapStyles[event.target.value])
        setMapStyle(event.target.value)
        closeAllOpenSidebars()
        // You can perform any actions based on the selected style here
    };

    const removeCheckboxesData = () => {
        // setSpotters(false);
        // spotters(false);
        // setradar(false);
        setRadarCheck(false);
        setSatelliteCheck(false);

        setSpottersCheck(false);
        setSpotterFavoritesCheck(false);

        setWarningsCheck(false);
        setSpcOutlookCheck(false);
        setTweetCheck(false);
        setTornadoReportsCheck(false);
        setHailReportsCheck(false);
        setWindReportsCheck(false);
        setOtherReportsCheck(false);

        setTvChannelsCheck(false);
        setWatchesCheck(false);
        setLegendChecks(false);
    };

    const {
        setTweetCheck,
        setSpottersCheck,
        setSpotterFavoritesCheck,
        setSpcOutlookCheck,
        setWarningsCheck,
        setWatchesCheck,
        setTornadoReportsCheck,
        setHailReportsCheck,
        setWindReportsCheck,
        setOtherReportsCheck,
        setSatelliteCheck,
        setTvChannelsCheck,
        setRadarCheck,

        tweetCheck,
        radarCheck,
        satelliteCheck,
        spottersCheck,
        spotterFavoritesCheck,
        tornadoReportsCheck,
        hailReportsCheck,
        windReportsCheck,
        otherReportsCheck,

        warningsCheck,
        spcOutlookCheck,
        tvChannelsCheck,
        watchesCheck,

        allActiveStreamers,

        outlookDay,
        setOutlookDay,
        showRightSidebar,
        isRightSidebarOpen,
        setIsRightSidebarOpen,
        metaData,
        legendChecks,
        setLegendChecks,
        setMapStyle,
        mapStyle,
        selectableMapStyles,
        setIsSidebarOpen,
        isSidebarOpen,
        isLayerMenuOpen,
        setIsLayerMenuOpen,
        isTopbarOpen,
        setIsTopbarOpen,
        layerGroupRef1,
        layerGroupRef2,
        isSpotterMenuOpen,
        setIsSpotterMenuOpen,
        setCookie
    } = useContext(StormContext);

    const handleLogout  = () => {
        logout();
    }

    const handleLogin = () => {
        navigate('/login');
    }    


    const showActiveStreamers = () => {
        console.log("Active Streamers", allActiveStreamers)
        showRightSidebar(
            <>
                <p className="text-base mt-1 text-white">
                    <h4>All active live streams</h4>
                    {allActiveStreamers.length > 0 ? (
                    <ul className="text-base mt-1 text-white">
                        {Object.entries(allActiveStreamers).map(([_, stream]) => (
                            stream !== null && (
                                <li key={stream.id}>

                                    <strong><a className="underline" href={stream.youtube_active_livestream.url} rel="noreferrer" target="_blank">{stream.youtube_active_livestream.title}</a> by {stream.sn_first_name} {stream.sn_last_name}</strong>
                                </li>
                            )
                        ))}                        
                    </ul>
                    ) : <>No ongoing live streams at the moment.</>}
                </p>
            </>
        );        
    }
    
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const showLastUpdates = () => {
        console.log("META DATA", metaData)
        if (isRightSidebarOpen) {
            setIsRightSidebarOpen(false);
            return;
        }

        let metaLookup = {
            'image_labels': "Image labels",
            'spc_reports': "SPC Reports",
            'spotter_reports': "Spotter Reports",
            'spotters': "Spotters",
            'text_labels': "Text labels",
            'tweets': "Tweets",
            'weather_alerts': "Warnings & Watches"
        }

        //console.log("MetaData", metaData)
        showRightSidebar(
            <>
                <p className="text-base mt-1 text-white">
                    <h4>Data last updated</h4>
                    <ul className="text-base mt-1 text-white">
                        {Object.entries(metaData).map(([metaItem, metaValue]) => (
                            metaValue !== null && metaItem !== 'tweets' && metaItem !== 'text_labels' && metaItem !== 'image_labels' && (
                                <li key={metaItem}>
                                    <strong>{metaLookup[metaItem]}:</strong> {timeSince(new Date(metaValue).toISOString())} ago
                                </li>
                            )
                        ))}
                    </ul>
                </p>
            </>
        );
    }

    const closeAllOpenSidebars = () => {
        if (isLayerMenuOpen) setIsLayerMenuOpen(false);
        if (isSidebarOpen) setIsSidebarOpen(false);
        if (isTopbarOpen) setIsTopbarOpen(false);
        if (isSpotterMenuOpen) setIsSpotterMenuOpen(false);
    }



    return (
        <ClickAwayListener onClickAway={(e) => {            
            if (
                (toggleBtnRef.current && toggleBtnRef.current.contains(e.target)) ||
                (layerGroupRef1.current && layerGroupRef1.current.contains(e.target)) || (layerGroupRef2.current && layerGroupRef2.current.contains(e.target)) 
              ) {
                return;
              }
             closeAllOpenSidebars()
        }}>
            <div
                className={`flex grow flex-col gap-y-1 box-border bg-[#012533] px-2 pt-2 w-full !h-screen overflow-y-auto relative overflow-x-hidden ${currentUrl === "/" ? "h-auto" : "h-screen"} pb-[160px] sm:pb-2`}
            >
                <a href='/'>
                    <div
                        className="h-16 shrink-0 items-center gap-x-3 cursor-pointer hidden lg:flex"
                    >
                        <img
                            className="h-12 w-auto"
                            src="/images/stormcenter-logo.webp"
                            alt="StormCenter"
                        />
                        <div>
                            <div className="text-right relative top-1"></div>
                            <h1 className="text-[1.2rem] leading-[0rem]">StormCenter</h1>
                            <span className="text-[0.65rem] leading-[1rem] text-[#ffffff80]">
                                by StormChasingUSA.com
                            </span>
                            <FontAwesomeIcon icon={faXmark}
                                className="text-white text-xl bottom-[22px] relative left-7 cursor-pointer inline md:hidden"
                                onClick={() => { setIsSidebarOpen(!isSidebarOpen) }}
                            />
                        </div>
                    </div>
                </a>

                <nav className="flex flex-1 flex-col">
                    <ul
                        className="flex flex-1 flex-col gap-y-2 list-none relative md:right-7 right-10"
                    >
                       {!isLayerMenuOpen && !isSpotterMenuOpen && <ParentComponent name="Map" icon={faMap} ml="39" url="/" />}
                        {(currentUrl === "/" || currentUrl.includes('/spotter')) ? (
                            <div onClick={() => { 
                                closeAllOpenSidebars();} }>

                            <li className={`${!isTopbarOpen ? 'block': 'hidden'} sm:block`}>
                                <ul
                                    className="w-full relative right-4 space-y-1 list-none"
                                >
                                    {!isLayerMenuOpen &&<div><ChildComponent
                                        name="Action Area"
                                        icon={faLocation}
                                        mapFunction={"ActionArea"}
                                    />

                                    <ChildComponent
                                        name="Last Tornado"
                                        icon={faTornado}
                                        mapFunction={"LastTornado"}
                                    />
                                    

                                    <ChildComponent name="Spotters" icon={faCar} link={"/spotters"} /></div>}

                                    {!isLayerMenuOpen ? (    
                                        <ChildComponent name={`Live streams (${allActiveStreamers.length})`} icon={faVideoCamera}  />
                                    ): null}
                                    

                                    {!isSpotterMenuOpen && <>
                                    <div className="md:block hidden"><ChildComponent name="SPC Outlooks" icon={faLayerGroup} /></div>
                                    <div className="md:ml-10 ml-4 cursor-pointer md:pb-2">
                                        <span className="md:hidden block text-lg">SPC Outlook</span>
                                        <select
                                            id="dropdown"
                                            value={outlookDay}
                                            onChange={onChangeHandler}
                                            onClick={stopPropagation} 
                                            className="md:mb-0 mb-1 bg-[#012635ad] text-white border-[1px] border-[#3CA6A6] xl:w-[75%] cursor-pointer w-full"
                                        >
                                            <option value="off" defaultChecked className="cursor-pointer">Off
                                            </option>       
                                    
                                            <option value="recently" className="cursor-pointer">Recently</option>
                                            <option value="day1" className="cursor-pointer">Day 1</option>
                                            <option value="day2" className="cursor-pointer">Day 2</option>
                                            <option value="day3" className="cursor-pointer">Day 3</option>
                                        </select>
                                    </div>

                                    <div className="md:block hidden"><ChildComponent name="Other Layers" icon={faLayerGroup} /></div>
                                    <div className="space-y-[0px] ml-3">
                                        <CheckboxComponent
                                            checkboxName="spotterCheck"
                                            label="Spotters"
                                            mt="1"
                                            checkbox={spotters}
                                            setCheckBox={setCheckBox}
                                            checked={spottersCheck}
                                        />

                                        { state.isAuthenticated && state.user ?
                                            <CheckboxComponent
                                                checkboxName="spotterFavoritesCheck"
                                                label="Favorites"
                                                mt="0"
                                                checkbox={spotterFavoritesCheck}
                                                setCheckBox={setCheckBox}
                                                checked={spotterFavoritesCheck}
                                            /> : <></>
                                        }
                                        {/*
                                        <CheckboxComponent
                                            checkboxName="tweetsCheck"
                                            label="Tweets & Photos"
                                            mt="0"
                                            checkbox={tweetCheck}
                                            setCheckBox={setCheckBox}
                                            defaultChecked={true}
                                            checked={tweetCheck}
                                        />*/}

                                        <CheckboxComponent
                                            checkboxName="radarCheck"
                                            label="Radar"
                                            mt="0"
                                            checkbox={radar}
                                            setCheckBox={setCheckBox}
                                            checked={radarCheck}
                                        />

                                        <CheckboxComponent
                                            checkboxName="satelliteCheck"
                                            label="Satellite"
                                            mt="0"
                                            checkbox={satelliteCheck}
                                            checked={satelliteCheck}
                                            onClick={stopPropagation}
                                        />

                                        <CheckboxComponent
                                            checkboxName="tornadoReportsCheck"
                                            label=" Tornado"
                                            mt="0"
                                            checkbox={tornadoReports}
                                            setCheckBox={setCheckBox}
                                            checked={tornadoReportsCheck}
                                        />

                                        <CheckboxComponent
                                            checkboxName="hailReportsCheck"
                                            label=" Hail"
                                            mt="0"
                                            checkbox={hailReports}
                                            setCheckBox={setCheckBox}
                                            checked={hailReportsCheck}
                                        />

                                        <CheckboxComponent
                                            checkboxName="windReportsCheck"
                                            label=" Wind"
                                            mt="0"
                                            checkbox={windReports}
                                            setCheckBox={setCheckBox}
                                            checked={windReportsCheck}
                                        />

                                        <CheckboxComponent
                                            checkboxName="otherReportsCheck"
                                            label=" Other Reports"
                                            mt="0"
                                            checkbox={otherReports}
                                            setCheckBox={setCheckBox}
                                            checked={otherReportsCheck}
                                        />

                                        <CheckboxComponent
                                            checkboxName="warningsCheck"
                                            label="Warnings"
                                            mt="0"
                                            checkbox={warnings}
                                            setCheckBox={setCheckBox}
                                            checked={warningsCheck}
                                        />

                                        <CheckboxComponent
                                            checkboxName="watchesCheck"
                                            label="Watches"
                                            mt="0"
                                            checkbox={watches}
                                            setCheckBox={setCheckBox}
                                            checked={watchesCheck}
                                        />

                                        <CheckboxComponent
                                            checkboxName="tvChannelsCheck"
                                            label="TV Channels"
                                            mt="0"
                                            checkbox={tvChannels}
                                            setCheckBox={setCheckBox}
                                            checked={tvChannelsCheck}
                                        />

                                        <CheckboxComponent
                                            checkboxName="legendCheck"
                                            label="Legend"
                                            mt="0"
                                            checkbox={legend}
                                            setCheckBox={setLegendChecks}
                                            checked={legendChecks}
                                        />
                                        {/* <button
                                            type="button"
                                            className="cursor-pointer border-none rounded !mt-[15px] bg-[#3CA6A6] px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-[#35d2d2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 relative bottom-2 left-1"
                                            onClick={removeCheckboxesData}
                                        >
                                            Remove All
                                        </button> */}
                                    </div>
                                    
                                    <div className="md:block hidden"><ChildComponent name="Map style" icon={faGear} /></div>
                                    <div className="md:ml-10 ml-4 cursor-pointer">
                                        <span className="md:hidden block text-lg">Map style</span>
                                        <select
                                            onChange={handleStyleChange}
                                            className="bg-[#012635ad] w-full text-white border-[1px] border-[#3CA6A6] xl:w-[85%] cursor-pointer"
                                            value={mapStyle}
                                            onClick={stopPropagation} 
                                        >

                                            {Object.entries(selectableMapStyles).map(([styleName, styleUrl]) => (
                                                <option key={styleName} value={styleName}>
                                                    {styleName}
                                                </option>
                                            ))}
                                        </select>
                                    </div></>}

                                    {!isLayerMenuOpen && !isSpotterMenuOpen && <ChildComponent name="Last Updated" icon={faArchive} onClick={showLastUpdates} />}
                                </ul>
                            </li>
                        </div>) : null}

                      {!isLayerMenuOpen && !isSpotterMenuOpen && <><ParentComponent
                            name="Storm History"
                            icon={faChartSimple}
                            ml="39"
                            url="/history"
                        />
                        <li>
                            <ul
                                className="w-full relative right-4 space-y-1 list-none"
                            >
                                <ChildComponent
                                    name="Year"
                                    icon={faTable}
                                    link={"/history/year"}
                                />

                                { /* hack to fix crashing month */}
                                <div
                                    className="w-[180px] pl-3 text-gray-300 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md !px-2 text-sm leading-6 font-semibold items-center"
                                >
                                    <div className="w-5 h-5 bg-white rounded-full flex items-center justify-center">
                                        <FontAwesomeIcon icon={faTable} className="text-[#012533] text-xs" />
                                    </div>
                                    <a className="relative whitespace-nowrap" href="/history/month">Month</a>
                                </div>

                            </ul>
                        </li>
                        {/*
                        <ParentComponent
                            name="24h Photos"
                            icon={faCamera}
                            ml="39"
                            url="/photos"
                        />

                        <ParentComponent
                            name="24h Tweets"
                            icon={faTwitter}
                            ml="39"
                            url="/tweets"
                        />*/}
                        <ParentComponent
                            name="Forecast"
                            icon={faEye}
                            ml="39"
                            url="/forecast"
                        />
                        
                        <ParentComponent
                            name="Feedback"
                            icon={faCommentAlt}
                            ml="39"
                            url="/feedback"
                        />
                        <ParentComponent
                            name="About"
                            icon={faCircleInfo}
                            ml="39"
                            url="/about"
                        />
                        <ParentComponent
                            name="Privacy"
                            icon={faMask}
                            ml="39"
                            url="/privacy"
                        /></>}
                        <div className='hidden sm:block'>  
                        <div className="mt-4">
                            <div className="text-white relative bottom-1 flex gap-x-2">
                                <button className="bg-slate-700 hover:bg-slate-500 text-white font-bold py-1 px-2 rounded shadow text-[0.80rem] whitespace-nowrap">
                                    {calculateCDTTimeFromNow(0)} CDT
                                </button>
                                <button className="bg-cyan-900 hover:bg-slate-500 text-white font-bold py-1 px-2 ml-3 rounded shadow text-[0.80rem] whitespace-nowrap">
                                    {calculateZuluTimeFromNow(0)} Z
                                </button>
                            </div>
                        </div>
                        <hr className="w-full mt-3" />

                        {state.isAuthenticated && state.user ? state.user : ""}
                        <div className='flex gap-3 w-full mt-2'>
                        {
                            state.isAuthenticated && state.user && (
                                    <div
                                    className="bg-white text-black w-1/2 p-1 font-bold hover:cursor-pointer text-center"
                                    onClick={() => navigate('/settings')}
                                    >
                                        Settings
                                    </div>
                                )
                            }                        
                        <button
                            className="cursor-pointer font-bold flex-1"
                            onClick={state.isAuthenticated ? handleLogout : handleLogin}
                            >
                            {
                                state.isAuthenticated && state.user ? "Log Out" : "Register / Log In"
                            }
                        </button>                        
                            </div>
                        <hr className="w-full mt-3" />
                        </div>
                        {!isLayerMenuOpen && !isSpotterMenuOpen && (
                       <>
                        <div className="flex flex-col gap-y-0 mx-1 mb-0">
                            <h6 className="leading-[0rem] text-[#ffffff8f]">CONTACT</h6>
                            <span className="leading-[0rem] text-[0.8rem] text-white relative bottom-1 mt-3">
                                christoffer@stormchasingusa.com
                            </span>
                            <h6 className=" text-[#ffffff8f] pt-3 my-3">
                                FOLLOW STORMCHASINGUSA
                            </h6>

                            <div className="flex justify-between mt-0">
                                <a target="_blank" rel="noreferrer" href="https://twitter.com/stormchasingusa"><img src={twitterLogo} className="w-5 h-5" alt="Twitter logo" /></a>
                                <a target="_blank" rel="noreferrer" href="https://instagram.com/stormchasingusa"><img src={instaLogo} className="w-5 h-5" alt="Instagram logo" /></a>
                                <a target="_blank" rel="noreferrer" href="https://facebook.com/stormchasingusa"><img src={fbLogo} className="w-5 h-5" alt="Facebook logo" /></a>
                                <a target="_blank" rel="noreferrer" href="https://www.stormchasingusa.com"><img src={urlLink} className="w-5 h-5" alt="Link" /></a>
                            </div>
                        </div>
                        </>)}         
                    </ul>
                </nav>
            </div>
        </ClickAwayListener>
    );
}

export default LeftSidebar;
