import SpottersFavorites from "pages/spotters/SpottersFavorites"
import Spotters from "pages/spotters/Spotters"

export const spottersRoutes = [
    {
        path: '/spotters',
        children: [
            {index: true, element: <Spotters />},
            {path: 'favorites', element: <SpottersFavorites />},
        ]
    }
]