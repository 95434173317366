import React, { useContext, useEffect, useMemo, useState, useRef, lazy, Suspense } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";
import { TwitterTweetEmbed } from "react-twitter-embed";
import classNames from 'classnames'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

// MAP
import Map, { useControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {MapboxOverlay} from '@deck.gl/mapbox';

import "../StormMap.css";
import MapService from "services/MapService";
import { MAP_SETTINGS } from "utils/settings";
// end MAP

// Other internals
import EventService from "services/EventService";
import { StormContext } from "contexts/StormContext";

import {
  getToolTipFromObject,
  getEventIcon
} from "utils/map-functions";

// Layers
import { 
  createTornadoReportsLayer,
  createHailReportsLayer,
  createWindReportsLayer
} from "utils/layers/spcReportsLayers"

import { 
  createTornadoWarningLayers,
  createSevereWarningLayers,
} from "utils/layers/alertLayers"

import { 
  createOutlookDaysCategoricalLayer,
  createOutlookDaysTornadoLayer
} from "utils/layers/outlookLayers"


// Components
const OutlookCard = lazy(() => import("components/storm-history-date/OutlookCard"));
const NotFound = lazy(() => import("pages/NotFound"));
const Loader = lazy(() => import("components/loader-gifs/Loader"));

function DeckGLOverlay(props) {
  const overlay = useControl(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
}

const EventDate = () => {
  const changeHandler = (e) => {
    if (e.target.name === "spcTornadoReportsCheck") {
      setShowTornadoReports(e.target.checked);
    } else if (e.target.name === "spcHailReportsCheck") {
      setShowHailReports(e.target.checked);
    } else if (e.target.name === "spcWindReportsCheck") {
      setShowWindReports(e.target.checked);
    } else if (e.target.name === "tornadoWarningsCheck") {
      setShowTornadoWarnings(e.target.checked);
    } else if (e.target.name === "severeWarningsCheck") {
      setShowSevereWarnings(e.target.checked);
    } else if (e.target.name === "outlookCheck") {
      setShowOutlook(e.target.checked);
    } else {
    }
  };

  const [viewport, setViewport] = useState({
    latitude: MAP_SETTINGS.default_latitude,
    longitude: MAP_SETTINGS.default_longitude,
    zoom: MAP_SETTINGS.zoom_continental, 
    projection: MAP_SETTINGS.default_projection,
    bearing: MAP_SETTINGS.default_bearing,
    pitch: MAP_SETTINGS.pitch_history_map,
  });

  const {
    //spotterReports,
    legendChecks,
    isModalOpen,
    modalComponent,
    setIsModalOpen,    
    zoomToPositionAndTime,
    setModalType,    
    showPopup: displayPopup,
  } = useContext(StormContext);

  const [cursor, setCursor] = useState("auto");
  const mapRef = useRef();
  let warningLayer = [];

  // DUPLICATED FROM STORMMAP.JS
  const handleCloseModal = (e) => {
    //e.stopPropagation();
    //e.preventDefault();
    setIsModalOpen(false);
  };
  // end: duplicated from stormmap.js


  const currentURL = "https://stormcenter.app" + window.location.pathname;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const yyyyMmDdPattern = /^\d{4}-\d{2}-\d{2}$/;

  const { eventDate: date } = useParams();
  const [eventTweets, setEventTweets] = useState([]);
  const [eventNews, setEventNews] = useState([]);
  const [eventReports, setEventReports] = useState([]);
  const [eventData, setEventData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  //const [spcSpcReports, setSpcSpcReports] = useState([]);
  const [spcDateTornadoReports, setSpcDateTornadoReports] = useState([]);
  const [spcDateHailReports, setSpcDateHailReports] = useState([]);
  const [spcDateWindReports, setSpcDateWindReports] = useState([]);
  const [historicalTornadoWarnings, setHistoricalTornadoWarnings] = useState([]);  
  const [historicalSevereWarnings, setHistoricalSevereWarnings] = useState([]);
  const [historicalOutlook, setHistoricalOutlook] = useState([]);  

  // Layers
  const [showTornadoReports, setShowTornadoReports] = useState(true);
  const [showHailReports, setShowHailReports] = useState(false);
  const [showWindReports, setShowWindReports] = useState(false);
  const [showTornadoWarnings, setShowTornadoWarnings] = useState(true);
  const [showSevereWarnings, setShowSevereWarnings] = useState(false);
  const [showOutlook, setShowOutlook] = useState(true);

  const navigate = useNavigate();

  const eventService = useMemo(() => new EventService(), []);
  const mapService = useMemo(() => new MapService(), []);  

  async function getSpcReports(reportType) {
    const spcHistoricalReports = await mapService.getHistoricalSpcReports(date,reportType + "_reports");
    const spcHistoricalReportsJson = JSON.parse(spcHistoricalReports)
    //console.log("SPC HISTORICAL", spcHistoricalReportsJson)

    const spcReportsJson = spcHistoricalReportsJson.filter((report) => {
      return (report.report_type === reportType)
    }).map((reportData) => {
      return {
          ...reportData, position: [Number(reportData.longitude), Number(reportData.latitude)],
      };
    });
    return spcReportsJson
  }

  // Map data
  useEffect(() => {
    async function getMapData() {

      // Tornado reports
      const tornadoReportsJson = await getSpcReports("tornado")
      setSpcDateTornadoReports(tornadoReportsJson)

      // Hail reports
      const hailReportsJson = await getSpcReports("hail")
      setSpcDateHailReports(hailReportsJson)
      
      // Wind reports 
      const windReportsJson = await getSpcReports("wind")
      setSpcDateWindReports(windReportsJson)
      
      // Warnings

      const warningsJson = await mapService.getHistoricalWarnings(date)
      JSON.parse(warningsJson).filter((warning) => {
        if (warning.severity_type === "Tornado Warning") {
          setHistoricalTornadoWarnings((prev) => [...prev, warning]);
        } else if (warning.severity_type === "Severe Thunderstorm Warning") {
          setHistoricalSevereWarnings((prev) => [...prev, warning]);
        }
      });

      // Outlook
      const outlookJson = await mapService.getHistoricalOutlook(date)
      console.log("OUTLOOK", JSON.parse(outlookJson)[0].day1_json)
      setHistoricalOutlook(JSON.parse(outlookJson)[0].day1_json)
      
    }

    getMapData();
  }, [date]);
  //

  const {isToggleTornadoDays, setIsToggleTornadoDays} = useContext(StormContext)

  const compactDate = useMemo(() => {
    return String(date).replaceAll("-", "");
  }, [date]);

  const clearMap = () => {
    setHistoricalOutlook([])
    setHistoricalTornadoWarnings([])
    setHistoricalSevereWarnings([])
  }

  const goToPreviousDay = () => {
    const currentDateObj = new Date(date);
    currentDateObj.setDate(currentDateObj.getDate() - 1);
    const previousDate = currentDateObj.toISOString().split("T")[0];
    navigate(`/history/${previousDate}`);
    clearMap()
  
  };

  // Function to navigate to the next day
  const goToNextDay = () => {
    const currentDateObj = new Date(date);
    currentDateObj.setDate(currentDateObj.getDate() + 1);
    const nextDate = currentDateObj.toISOString().split("T")[0];
    navigate(`/history/${nextDate}`);
    clearMap()
  };


  useEffect(() => {
    setEventData(null);
    setIsLoading(true);
    if (!yyyyMmDdPattern.test(date) || new Date(date) > new Date()) {
      throw new Error('404 NotFound'); // Throw a 404 error
    }
    (async () => {
      Promise.all([
        eventService.getEventTweets(date),
        eventService.getEventData(date),
        eventService.getEventNews(date),
        eventService.getEventReports(date),
      ])
        .then(([eventTweets, eventData, eventNews, eventReports]) => {
          //console.log("EVENT DATA", eventData )
          setEventTweets(JSON.parse(eventTweets));
          setEventData(eventData);
          setEventNews(eventNews);
          setEventReports(
            eventReports?.sort((a, b) => a.time_original - b.time_original)
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    })();
  }, [date]);


// screen size
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  // Row of tornado reports
  const TornadoTableRow = (tornadoReport) =>{
    let report = tornadoReport.tornadoReport
    return(
      
      <tr className={`bg-transparent  text-white border-solid border-0 border-b border-[#ffffff54]  ${
        (report.injuries || report.fatalities) ? "text-[#ff0000]" : ""}`} >
      <td className='p-[8px]  align-baseline'>
        {report.time_original}
      </td>
      <td className='p-[8px]  align-baseline'>
        {report.state}
      </td>
      <td className='p-[8px]  align-baseline'>
        {report.county}
      </td>
      <td className='p-[8px]  align-baseline'>
        {report.f_scale_enhanced ?? ''}
      </td>
      <td className='p-[8px]   w-3/6 '>
        {report.comments}
      </td>
  
     </tr>
    )
  }

  const TopSection = () => {
    return (
      <>
        <h1 className={"m-0 font-light"}>
        {date} {eventData?.name || eventData?.title}
        </h1>
        <p
          className={"text-[#ffffff8f] text-normal font-extrabold uppercase"}
        >
          {eventData?.taglines && (
            <>
              {eventData?.taglines?.join(", ")}
              <br />
            </>
          )}
          {eventData?.affected_states[0].tornadoReports.length > 0 && (
            <>
              TORNADOES IN{" "}
              {eventData?.affected_states[0]?.tornadoReports
                ?.map((report) => report.state)
                ?.join(", ")}
            </>
          )}
        </p>
      </>
    )
  }

  const PreviousDay = () => {
    return (
      <button
        className={
          "border border-white cursor-pointer bg-transparent text-lg text-white rounded-[4px] p-1 "
        }
        onClick={goToPreviousDay}
      >
        &lt; Previous day
    </button>      
    )
  }

  const NextDay = () => {
    return (
      <button
        className={
          "border border-white cursor-pointer bg-transparent text-lg text-white rounded-[4px] p-1 "
        }
        onClick={goToNextDay}
      >
        Next day &gt;
    </button>      
    )
  }


  // SPC REPORTS: Tornado Layer
  const historicalTornadoReportsLayer = createTornadoReportsLayer({
    layer_name: "historical-tornado-reports-layer",
    spcTornadoReports: spcDateTornadoReports,
    tornadoReportsCheck: showTornadoReports,
    displayPopup,
    zoomToPositionAndTime,
    setModalType,
    mapSource: "event"
  })


  // SPC REPORTS: Hail Layer
  const historicalHailReportsLayer = createHailReportsLayer({
    layer_name: "historical-hail-reports-layer",
    spcHailReports: spcDateHailReports,
    hailReportsCheck: showHailReports,
    displayPopup,
    zoomToPositionAndTime,
    setModalType
  })

  

  // SPC REPORTS: Wind Layer
  const historicalWindReportsLayer = createWindReportsLayer({
    layer_name: "historical-wind-reports-layer",
    spcWindReports: spcDateWindReports,
    windReportsCheck: showWindReports,
    displayPopup,
    zoomToPositionAndTime,
    setModalType
  })


  // WARNINGS

  // TORNADO WARNINGS
  warningLayer.push(createTornadoWarningLayers({
    tornadoWarnings: historicalTornadoWarnings,
    warningsCheck: showTornadoWarnings,
    displayPopup,
    setModalType
  }))    

  // SEVERE WARNINGS
  warningLayer.push(createSevereWarningLayers({
    severeWarnings: historicalSevereWarnings,
    warningsCheck: showSevereWarnings,
    displayPopup,
    setModalType
  }))    

  // Outlooks Categorical layer
  const outlookDaysCategoricalLayer = historicalOutlook ? createOutlookDaysCategoricalLayer({
    outlookDaysData: historicalOutlook,
    checkOutlookDays: showOutlook,
    displayPopup,
    setModalType
  }) : null

  // Outlooks Tornado Layer
  const outlookDaysTornadoLayer = historicalOutlook ? createOutlookDaysTornadoLayer({
    outlookDaysData: historicalOutlook,
    checkOutlookDays: showOutlook,
    displayPopup,
    setModalType
  }) : null


  const mapLayers = [
    // watchLayer,
    warningLayer,                
    historicalTornadoReportsLayer,
    historicalHailReportsLayer,
    historicalWindReportsLayer
  ]
  if (outlookDaysTornadoLayer) {
    mapLayers.unshift(outlookDaysTornadoLayer);
  }  
  if (outlookDaysCategoricalLayer) {
    mapLayers.unshift(outlookDaysCategoricalLayer);
  }



  // RETURN
  if (!yyyyMmDdPattern.test(date)) {
    return <Suspense fallback={<Loader />}><NotFound /></Suspense>;
  } else {
    return isLoading ? (
      <Suspense fallback={<Loader />}><Loader /></Suspense>
    ) : (
    // top content with next and previous bttons
    <div className={"p-4"}>
      <Helmet>
        <title>{"Explore storms and " + eventData?.tornado_reports + " tornadoes on " + date + " with an interactive map"}</title>
        <meta 
          name="description" 
          content={
            (eventData.taglines ? eventData.taglines[0] + '.'  : '') + "Top storm/tornado photos, news, historical tweets about storms on " +
            date +
            ". A day with " + eventData?.tornado_reports + " tornadoes " + eventData?.affectedStates + ". Experience the setup and the results on this day in storm history."
          } 
        />
        <link rel="canonical" href={currentURL} />
      </Helmet>  

      {/*Buttons and text*/}
      {windowWidth >= 640 ?  
        // DESKTOP
        <div className={"flex flex-col sm:flex-row sm:justify-between items-center text-center gap-2"}>

          <div className={"order-1 sm:mr-0 sm:order-1"}>
            <PreviousDay />
          </div>
          <div className={"order-2 sm:order-2"}>
            <TopSection />
          </div>
          <div className={"order-3 sm:ml-0 sm:order-3"}>
            <NextDay />
          </div>
        </div>
        : // MOBILE
        <div className={"grid grid-cols-1 text-center"}>
          <div className='flex justify-around'>
            <div className={""}>
              <PreviousDay />
            </div>
            <div className={""}>
              <NextDay />
            </div>
          </div>
          <div className={"my-3"}>
            <TopSection />
          </div>
        </div>
      }


      {/*5 Cards*/}
      <div className={" text-center sm:grid sm:grid-cols-1 md:grid-cols-2 gap-x-4 py-4"}>
        {/* first three cards in a single grid item */}
        <div className={" sm:grid grid-cols-3 gap-x-4 "}>
          <div
            className={
              "my-1 sm:m-0 bg-[#f50016] rounded-[4px] py-4 px-1 flex flex-col justify-center items-center"
            }
          >
            <h1 className={"text-[25px] m-0 font-bold text-black"}>
              {eventData?.tornado_reports}
            </h1>
            <p className={"text-[15px] m-0 mt-2 text-black"}>Tornado reports</p>
          </div>
          <div
            className={
              "my-1 py-4 sm:m-0 bg-[#7daeba] rounded-[4px] flex flex-col justify-center items-center"
            }
          >
            <h1 className={" text-[25px] m-0 font-bold text-black"}>
              {eventData?.hail_reports}
            </h1>
            <p className={"text-[15px] m-0 mt-2 text-black"}>Hail reports</p>
          </div>
          <div
            className={
              "py-4 my-1 sm:m-0 bg-[#7daeba] rounded-[4px] flex flex-col justify-center items-center"
            }
          >
            <h1 className={"text-[25px] font-bold m-0 text-black"}>
              {eventData?.wind_reports}
            </h1>
            <p className={"text-[15px] m-0 mt-2 text-black"}>Wind reports</p>
          </div>
        </div>
              {/* // other 2 cards */}
        <div className={" my-1 md:m-0 sm:grid sm:grid-cols-2 gap-x-4 text-center"}>
          <div
            className={`py-4 px-1 my-1 sm:m-0 lvl-bg-${eventData?.day1_max_categorical} rounded-[4px] flex flex-col justify-center items-center`}
          >
            <h1 className={"text-[25px] font-bold m-0 text-black"}>
              {eventData?.day1_max_categorical}
            </h1>
            <p className={"text-[15px] m-0 mt-2 text-black"}>
              Categorical outlook @2000Z
            </p>
          </div>
          <div
            className={`py-4 tor-lvl-bg-${eventData?.day1_max_tornado} rounded-[4px] flex flex-col justify-center items-center`}
          >
            <h1 className={"text-[25px] font-bold m-0 text-black"}>
              {eventData?.day1_max_tornado}%
            </h1>
            <p className={"text-[15px] m-0 mt-2 text-black"}>
              Tornado outlook @2000Z
            </p>
          </div>
        </div>
      </div>


      {/*  Wikipedia about this outbreak  */}
      {eventData?.wiki_path_title && (
        <div>
          <p className={"font-extrabold text-[24px] m-0 mt-3 mb-1.5"}>
            Wikipedia about this outbreak
          </p>
          <div className={"sm:grid sm:grid-cols-1 md:grid-cols-12  gap-2"}>
            {eventData?.wiki_image_url && 
                <div
                className={" md:col-span-4 flex justify-center flex-col items-start"}
                >
                <img
                  src={eventData?.wiki_image_url}
                  alt=""
                  className={"w-full h-full object-cover"}
                />
              </div>
            }

            <div
              className={" md:col-span-8 flex justify-center flex-col items-center"}
            >
              <div>
                <ul className={"pl-6"}>
                  <li>
                    Wikipedia:{" "}
                    <a
                      href={eventData?.wiki_url}
                      target="_blank"
                      rel="noreferrer"
                      className={"underline cursor-pointer"}
                    >
                      {" "}
                      {decodeURIComponent(eventData?.wiki_path_title)}
                    </a>
                  </li>
                  <li>When: {eventData?.date_range}</li>
                  <li>Tornadoes: {eventData?.tornadoes}</li>
                  <li>Fatalities: {eventData?.fatalities}</li>
                </ul>
                <p className="ml-[12px]">
                  Note that these numbers relate to the entire outbreak, not
                  necessarily this particular date
                </p>
                <p className="ml-[12px]"
                  dangerouslySetInnerHTML={{ __html: eventData?.description }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    
      <div className="flex flex-col lg:grid lg:grid-cols-12 lg:gap-4 h-auto lg:h-[calc(60vh-62px)] mb-4">

      <div className="w-full mb-4 lg:mb-0 lg:col-span-2 flex-shrink-0">
        {/* Put your OptionColumn components here */}
        <div>          
          <h3>Layers</h3>

          <div className={`relative flex  !mt-3 `}>
              <div className="flex h-6 items-center ">
                  <input
                      id="spcTornadoReportsCheck"
                      aria-describedby="comments-description"
                      name="spcTornadoReportsCheck"
                      type="checkbox"
                      value="spcTornadoReportsCheck"
                      // defaultChecked={defaultChecked}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                      onChange={changeHandler}
                      checked={showTornadoReports}
                  />
              </div>
              <div className="ml-3 text-sm leading-6 cursor-pointer">
                  <label
                      htmlFor="spcTornadoReportsCheck"
                      className="lg:inline-block text-gray-300 hover:text-white text-base leading-6 cursor-pointer"
                  >
                      Tornado reports
                  </label>
              </div>
          </div>


          <div className={`relative flex  !mt-3 `}>
              <div className="flex h-6 items-center ">
                  <input
                      id="spcHailReportsCheck"
                      aria-describedby="comments-description"
                      name="spcHailReportsCheck"
                      type="checkbox"
                      value="spcHailReportsCheck"
                      // defaultChecked={defaultChecked}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                      onChange={changeHandler}
                      checked={showHailReports}
                  />
              </div>
              <div className="ml-3 text-sm leading-6 cursor-pointer">
                  <label
                      htmlFor="spcHailReportsCheck"
                      className="lg:inline-block text-gray-300 hover:text-white text-base leading-6 cursor-pointer"
                  >
                      Hail reports
                  </label>
              </div>
          </div>          

          <div className={`relative flex  !mt-3 `}>
              <div className="flex h-6 items-center ">
                  <input
                      id="spcWindReportsCheck"
                      aria-describedby="comments-description"
                      name="spcWindReportsCheck"
                      type="checkbox"
                      value="spcWindReportsCheck"
                      // defaultChecked={defaultChecked}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                      onChange={changeHandler}
                      checked={showWindReports}
                  />
              </div>
              <div className="ml-3 text-sm leading-6 cursor-pointer">
                  <label
                      htmlFor="spcWindReportsCheck"
                      className="lg:inline-block text-gray-300 hover:text-white text-base leading-6 cursor-pointer"
                  >
                      Wind reports
                  </label>
              </div>
          </div>

          { (historicalTornadoWarnings.length > 0 && historicalSevereWarnings.length > 0) && 
          <>
          <div className={`relative flex  !mt-3 `}>
              <div className="flex h-6 items-center ">
                  <input
                      id="tornadoWarningsCheck"
                      aria-describedby="comments-description"
                      name="tornadoWarningsCheck"
                      type="checkbox"
                      value="tornadoWarningsCheck"
                      // defaultChecked={defaultChecked}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                      onChange={changeHandler}
                      checked={showTornadoWarnings}
                  />
              </div>
              <div className="ml-3 text-sm leading-6 cursor-pointer">
                  <label
                      htmlFor="tornadoWarningsCheck"
                      className="lg:inline-block text-gray-300 hover:text-white text-base leading-6 cursor-pointer"
                  >
                      Tornado warnings
                  </label>
              </div>
          </div>

          <div className={`relative flex  !mt-3 `}>
              <div className="flex h-6 items-center ">
                  <input
                      id="severeWarningsCheck"
                      aria-describedby="comments-description"
                      name="severeWarningsCheck"
                      type="checkbox"
                      value="severeWarningsCheck"
                      // defaultChecked={defaultChecked}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                      onChange={changeHandler}
                      checked={showSevereWarnings}
                  />
              </div>
              <div className="ml-3 text-sm leading-6 cursor-pointer">
                  <label
                      htmlFor="severeWarningsCheck"
                      className="lg:inline-block text-gray-300 hover:text-white text-base leading-6 cursor-pointer"
                  >
                      Severe warnings
                  </label>
              </div>
          </div>  
          </> 
          }   

          { historicalOutlook && 
            <div className={`relative flex  !mt-3 `}>
                <div className="flex h-6 items-center ">
                    <input
                        id="outlookCheck"
                        aria-describedby="comments-description"
                        name="outlookCheck"
                        type="checkbox"
                        value="outlookCheck"
                        // defaultChecked={defaultChecked}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                        onChange={changeHandler}
                        checked={showOutlook}
                    />
                </div>

                  <div className="ml-3 text-sm leading-6 cursor-pointer">
                      <label
                          htmlFor="outlookCheck"
                          className="lg:inline-block text-gray-300 hover:text-white text-base leading-6 cursor-pointer"
                      >
                          Outlooks @1200Z
                      </label>
                  </div>

            </div>                          
          }          



        </div>
      </div>
      <div className="w-full mb-4 lg:mb-0 lg:col-span-6 flex-grow">
        <div className="relative flex justify-center" style={{ height: '50vh' }}>
        {legendChecks ? (
          <>
            <div className=" bg-amber-50 absolute z-50 ml-3 lg:ml-10 mt-10 group px-2 flex py-2 left-0">
              <div className="flex flex-col justify-center h-45 gap-1">
                <img src={getEventIcon('tornado')} alt="tornado icon" className="w-4 h-4" />
                <img src={getEventIcon('tornado_night')} alt="tornado night" className="w-4 h-4" />
                <img src={getEventIcon('tornado_sunset')} alt="tornado sunset" className="w-4 h-4" />
                <img src={getEventIcon('landspout')} alt="landspout icon" className="w-4 h-4" />
                <img src={getEventIcon('landspout_night')} alt="landspout night" className="w-4 h-4" />
                <img src={getEventIcon('hail')} alt="hail icon" className="w-4 h-4" />
                <img src={getEventIcon('wind')} alt="wind icon" className="w-4 h-4" />
                {/*<img src="/images/twitter.webp" alt="twitter icon" className="w-4 h-4" />
                <img src="/images/video.webp" alt="video icon" className="w-4 h-4" />*/}
              </div>

              <div className="group-hover:flex hidden px-2 flex-col justify-start gap-1 [&>h1]:text-black [&>h1]:text-[10px] [&>h1]:my-[2px]">
                <h1>Tornado report</h1>
                <h1>Tornado (night)</h1>
                <h1>Tornado (sunset)</h1>
                <h1>Landspout tornado report</h1>
                <h1>Landspout (night)</h1>
                <h1>Hail report</h1>
                <h1>Wind report</h1>
                {/*<h1>Tweet at given position</h1>
                <h1>Tweet video at given position</h1>*/}
              </div>
            </div>
          </>
        ) : null} 
        <Map
          mapStyle= "mapbox://styles/mapbox/light-v11?optimize=true"
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
          onClick={(e) => e.preventDefault()}
          onViewportChange={setViewport}
          style={{ position: "relative", height: "100%", width: "100%" }}
          initialViewState={viewport}
          
        >
            <DeckGLOverlay
              layers={mapLayers}
              style={{ position: "relative", height: "100%", width: "100%" }}
              controller={{ dragRotate: false }}
              
              ref={mapRef}
              getCursor={() => cursor}
              onClick={(e) => {
                console.log(e);
              }}
              onHover={(event) => {
                // Update cursor style based on hover event
                setCursor(event.object ? "pointer" : "grab");
              }}
              getTooltip={({ object }) => {
                // MAP TOOLTIPS: Tooltips on different types of objects in map. Hack: Looks at unique data properties to determine type of object.
                //console.log("Object Tooltip", object)
                // Spotters
                const tooltipText = getToolTipFromObject(object, 'event_date');
                var tooltipObject = {
                  html: `<div>${tooltipText}</div>`,
                  style: {
                    color: "white",
                    padding: "5px 10px", // Adequate padding
                    boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
                    textAlign: "center", // Text alignment
                    // Dynamic sizing based on content
                    position: "relative",
                    marginLeft: "10px", // Right offset from the pointer
                    marginTop: "-25px", // Above the pointer
                    whiteSpace: "normal", // Allows natural text wrapping
                    wordWrap: "break-word", // Ensures words do not overflow
                    maxWidth: "220px", // Limit width to 200px for very long text
                  },
                };
                if (object) {
                  return tooltipObject;
                }
              }}
              interleaved={true}
            />
        </Map>
          </div>

      </div>
      <div className="w-full lg:col-span-4 flex-shrink-0">

        {/* Tornado report list section */}
        <div className="overflow-y-auto pb-6" style={{ maxHeight: '50vh' }}>
          <h3>Tornado report list</h3>          
          <div className="tornado-reports-table overflow-x-auto">
          
            <table className='w-full table border-collapse border-spacing-0.5 bg-[#012432ba] rounded-md'>
              <thead>
              <tr className='bg-transparent border-solid border-0 border-b border-[#ffffff54]'>
                  <th className=' font-bold text-left p-[8px]'>
                  Time
                  </th>
                  <th className=' font-bold text-left p-[8px]'>
                  State
                  </th>
                  <th className=' font-bold text-left p-[8px]'>
                  County
                  </th>
                  <th className=' font-bold text-left p-[8px] min-w-[70px]'>
                  EF/F-Scale
                  </th>
                  <th className=' font-bold text-left p-[8px] '>
                  Comment

                  </th>
                </tr>
              </thead>
              <tbody>
                {eventReports?.map((tornadoReport) => {
                  return (
                    <TornadoTableRow key={tornadoReport.id} tornadoReport={tornadoReport} />
                  );
                })}                      
              </tbody>
            </table>
          </div>
        </div>
      </div>      
      {/* Modal i.e. Popup */}
      {isModalOpen && (
        <div
          className="fixed inset-0 z-[1000] flex items-center justify-center w-screen h-screen bg-black bg-opacity-50"
          onClick={handleCloseModal}
        >
          <div
            className="relative max-w-[50%] w-full bg-white rounded-lg shadow-lg p-4 max-h-[90%] overflow-y-auto pb-5 mx-2"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-end mb-3">
              <FontAwesomeIcon
                icon={faXmark}
                className="text-[#012533] text-lg cursor-pointer text-right "
                onClick={handleCloseModal}
              />
            </div>
            {modalComponent}
          </div>
        </div>
      )}      
      </div>      


      {/*  SPC Outlooks  */}   
      <div className={" sm:grid max-[865px]:grid-cols-2 min-[870px]:grid-cols-3 gap-x-4 py-4"}>  
        <OutlookCard type={"convective"} />   
        <OutlookCard type={"tornado"} />
        <div className="my-1">
          <h1 className={"m-0 ml-2 mt-10 md:mt-0 mb-1.5 text-[24px] font-thin"}>Storm Reports</h1>
          <a
            href={`https://www.spc.noaa.gov/climo/reports/${String(
              compactDate
            ).substr(2, 8)}_rpts.html`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`https://www.spc.noaa.gov/climo/reports/${String(
                compactDate
              ).substr(2, 8)}_rpts.gif`}
              alt=""
              className={"w-full h-[300px] object-cover"}
            />
          </a>
        </div>
      </div>


      {/*  Most liked Twitter storm photos today  */}
      {eventData?.most_liked_tweets?.length > 0 && (
        <div>
          <p className={"font-normal text-[24px] m-0 mt-3 mb-1.5"}>
            Most liked Twitter storm photos today
          </p>

          <div className={"flex justify-around jmd:ustify-between flex-1 flex-wrap"}>
            {eventData?.most_liked_tweets?.slice(0, 4).map((tweet) => {
              return (
                <div className={""}>
                  <div className={" "}>
                    <TwitterTweetEmbed
                      key={tweet.id}
                      tweetId={tweet?.tweet_numeric.toString()}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}


      {/*  Outlook text & Weather data from this day  */}
      <div className={"md:h-[45vh] overflow-auto md:border-2 md:border-solid md:border-white p-4"}>
        <div className={"flex flex-col-reverse md:grid md:grid-cols-2 gap-x-4 py-4"}>
          <div className={""}>
            <div>
              <h1 onClick={() => setIsToggleTornadoDays(!isToggleTornadoDays)}>
                Outlook text
                <FontAwesomeIcon icon={faChevronDown} className={classNames("inline md:hidden transform ml-2", {
                  'rotate-180': isToggleTornadoDays,
                })}/></h1>
              <div className={classNames('md:block', {
                'hidden': !isToggleTornadoDays
              })}>
                {eventData ? (
                  <>
                    <p>Affected cities: {eventData.affected_cities ?? "-"}</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: eventData?.outlook_text?.replaceAll("\n", "<br/>"),
                      }}
                    ></p>
                  </>
                ) : (
                  <p>No outlook text exist for this date (at the moment).</p>
                )}
              </div>
            </div>
          </div>
          <div className={""}>
            <div>
              <h1>Weather data from this day</h1>
              <ul>
                <li>
                  <a
                    className={"underline cursor-pointer"}
                    href={`https://www.spc.noaa.gov/exper/archive/event.php?date=${compactDate}`}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    SPC Severe Thunderstorm Events page
                  </a>
                  &nbsp; with Skew-T, Mesoscale discussions, Upper air analysis
                  etc from this day
                </li>
                {eventData?.stormtrack_title && (
                  <li>
                    <a
                      className={"underline cursor-pointer"}
                      href={eventData?.stormtrack_url}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      StormTrack forum thread about this day:
                    </a>{" "}
                    {eventData?.stormtrack_title}{" "}
                    {eventData.stormtrack_replies &&
                      `(${eventData.stormtrack_replies} replies)`}
                  </li>
                )}
              </ul>
            </div>
            {eventData?.resources.length > 0 && (
              <div>
                <h1>Videos and other external resources</h1>
                <ul>
                  {eventData?.resources.map((resource) => {
                    return (
                      <li>
                        <a
                          className={"underline cursor-pointer"}
                          href={resource?.url}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          {resource?.name}
                        </a>
                        &nbsp;({resource?.resource_type})
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

    </div>
  )};
};

export default EventDate;