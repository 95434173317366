import React, { useState, useEffect, useContext, useRef, useCallback, useMemo, lazy, Suspense } from "react";
import {Helmet} from "react-helmet";
import Map, { GeolocateControl, Popup, useControl } from "react-map-gl";
import {MapboxOverlay} from '@deck.gl/mapbox';
import {useNavigate, useLocation } from 'react-router-dom'; // Used for AudienceMe
import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faCog,
  faCrosshairs,
  faLayerGroup,
  faSearch,
  faShareNodes,
  faSliders,
  faEnvelope,
  faSlidersH,
  faXmark,
  faMailForward
} from "@fortawesome/free-solid-svg-icons";

import {
  faFacebook,
  faTwitter,
  faReddit,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";

import useMediaQuery from "@mui/material/useMediaQuery";
import { Slider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

//import { useCookies } from 'react-cookie'; // Used for AudienceMe

// Internal

// Layers
import { 
  createSatelliteLayer,
  createRadarLayer
} from "utils/layers/externalLayers"
import { 
  createTornadoWarningLayers,
  createSevereWarningLayers,
  createSevereWatchLayers,
  createTornadoWatchLayers
} from "utils/layers/alertLayers"
import { 
  createOutlookDaysCategoricalLayer,
  createOutlookDaysTornadoLayer
} from "utils/layers/outlookLayers"
import { 
  createTornadoReportsLayer,
  createHailReportsLayer,
  createWindReportsLayer
} from "utils/layers/spcReportsLayers"
import { 
  createSpotterReportsLayer,
} from "utils/layers/spotterReportsLayers"
import { 
  createSpottersLayer,
  createArcLayerSpotterPos
} from "utils/layers/spottersLayers"
import {
  createTvChannelsLayer
} from "utils/layers/otherLayers"
import GeocoderControl from "components/storm-map/GeocoderControl";
import {
  customTimestampTransform,
  timeSince,
  calculateIsoTimeFromSlider,
} from "utils/general-functions";
import {
  getToolTipFromObject,
  getEventIcon
} from "utils/map-functions";
// import { 
//   createPositionedPhotosLayer,
//   createTweetsLayer,
//   createVideoLayer,
// } from "utils/layers/tweetLayers"

//import {getTweetGpsFromLastPosition} from "utils/map-tweet-functions"

import { StormContext } from "contexts/StormContext";
import { SliderTimeIndicator } from "components/storm-map/SliderTimeIndicator";

import hideLayerIcon from 'assets/images/icons/hide-layer.webp';

// CSS
import "mapbox-gl/dist/mapbox-gl.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./StormMap.css";

// Services
import OutlookService from "services/OutlookService";
import MapService from "services/MapService";
import SpotterService from "services/SpotterService";
//import TweetService from "services/TweetService";
// import { create } from "@mui/material/styles/createTransitions";

// Components (lazy and must be placed after other imports)
const Loader = lazy(() => import("components/loader-gifs/Loader")); 
const LoaderSmall = lazy(() => import("components/loader-gifs/LoaderSmall"));

function DeckGLOverlay(props) {
  const overlay = useControl(() => new MapboxOverlay(props));
  if (overlay) {
    //console.log("Overlay setProps", props)
    overlay.setProps(props);
  }
  return null; 
}

function StormMap() {
 
  //const DeckGL = lazy(() => import('@deck.gl/react').then(module => ({ default: module.DeckGL })));


  // Services
  const mapService = new MapService();
  const outlookService = new OutlookService();
  //const tweetService = new TweetService();
  const spotterService = new SpotterService();
  const navigate = useNavigate();
  const {
    mapInstance,
    layerGroupRef1,
    layerGroupRef2,
    spcTornadoReports,
    spcHailReports,
    spcWindReports,
    spotterReports,
    tornadoWarnings,
    severeWarnings,
    tornadoWatches,
    severeWatches,
    tvChannels,
    spotterFavorites,
    //watches,
    zoomToPositionAndTime,

    radarCheck,
    satelliteCheck,
    //tweetCheck,
    spottersCheck,
    setSpottersCheck,
    spotterFavoritesCheck,
    setSpotterFavoritesCheck,
    setRadarCheck,
    setSatelliteCheck,
    setLegendChecks,
    tornadoReportsCheck,
    setTornadoReportsCheck,
    hailReportsCheck,
    setHailReportsCheck,
    windReportsCheck,
    setWindReportsCheck,
    otherReportsCheck,
    setOtherReportsCheck,
    tvChannelsCheck,
    setTvChannelsCheck,
    warningsCheck,
    setWarningsCheck,
    watchesCheck,
    setWatchesCheck,
    setOutlookDay,
    spcOutlookCheck,
    setSpcOutlookCheck,
    legendChecks,
    setViewport,
    viewport,
    outlookDay,
    setSpcOutlooks,
    sliderValue,
    setSliderValue,
    timeFormat,
    setTimeFormat,
    isModalOpen,
    modalComponent,
    setIsModalOpen,
    modalType,
    setModalType,
    selectableMapStyles,
    setMapStyle,
    mapStyle,
    setIsSidebarOpen,
    setIsTopbarOpen,
    setIsLayerMenuOpen,
    isTimeIndicatorOpen,
    setIsTimeIndicatorOpen,
    isPositionIconOpen,
    setIsPositionIconOpen,
    isSpotterMenuOpen,
    setIsRightSidebarOpen,
    setIsSpotterMenuOpen,
    isSearchFieldOpen,
    setIsSearchFieldOpen,
    showPopup: displayPopup,
  } = useContext(StormContext);

  // useStates
  const [spotters, setSpotters] = useState([]);
  const [activeSpotters, setActiveSpotters] = useState([]);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [userChecksLoaded, setUserChecksLoaded] = useState(false);
  const [isLoadingSmall, setIsLoadingSmall] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [cursor, setCursor] = useState("auto");

  const [allFavoritesPositions, setAllFavoritesPositions] = useState([]);
  const [favoritesPositions, setFavoritesPositions] = useState([]);  

  const [spotterLayer, setSpotterLayer] = useState(null);
  const [tornadoReportsLayer, setTornadoReportsLayer] = useState(null);
  const [hailReportsLayer, setHailReportsLayer] = useState(null);
  const [windReportsLayer, setWindReportsLayer] = useState(null);
  const [spotterReportsLayer, setSpotterReportsLayer] = useState(null);
  const [arcLayerSpotterPos, setArcLayerSpotterPos] = useState(null);

// AUDIENCE_ME / ARCS
  // const [followChristofferCookie, setFollowChristofferCookie] = useCookies('StormCenter-Christoffer'); 
  // const [christofferPositions, setChristofferPositions] = useState([]);

  const location = useLocation();

  // const [selectedTweet, setSelectedTweet] = useState(null);
  // const [allTweets, setAllTweets] = useState([]);
  // const [allStormPhotos, setAllStormPhotos] = useState([]);
  // const [positionedStormPhotos, setPositionedStormPhotos] = useState([]);
  // const [positionedTweets, setPositionedTweets] = useState([]);
  // const [positionedVideos, setPositionedVideos] = useState([]);

  const [checkOutlookDays, setCheckOutlookDays] = useState(false);
  const [outlookDaysData, setOutlookDaysData] = useState([]); 
  //const [satelliteLayer, setSatelliteLayer] = useState(null);
  //const [radarLayer, setRadarLayer] = useState(null);
  
  const [item, setItem] = useState(0); // What is this?!
  const bufferedSliderValue = useDebounce(sliderValue, 300); // Delays slider value 300 ms before setting to avoid multiple calls
  const now = new Date();

  const handleSliderChange = (event, newValue) => {
    console.log("handleSliderChange")
    setSliderValue(newValue)
  }

  
  // Helper functions
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
  
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
  
    return debouncedValue;
  }


  const checkMediaType = (tweet) => {
    if (tweet.videoUrl) {
      let videoFileExtension = tweet.videoUrl.split(".").pop().toLowerCase();
      if (["mp4", "webm", "avi", "mov"].includes(videoFileExtension)) {
        return "video";
      }
    } else if (tweet.imageUrl) {
      let imageFileExtension = tweet.imageUrl.split(".").pop().toLowerCase();
      if (["jpg", "jpeg", "png", "gif"].includes(imageFileExtension)) {
        return "image";
      }
    } else {
      return null;
    }
  };


  /* Bottom slider mobile hack */
  const [browser, setBrowser] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    
    if (userAgent.indexOf('chrome') > -1 && userAgent.indexOf('safari') > -1) {
      //console.log('Chrome', userAgent);
      setBrowser('chrome');
    } else if (userAgent.indexOf('safari') > -1) {
      setBrowser('safari');
    }
  }, []);  


  useEffect(() => {
    let failSafeTimer;

    if (isLoadingSmall) {
      failSafeTimer = setTimeout(() => {
        setIsLoadingSmall(false);
      }, 5000); // Fail-safe to remove loading after 5 seconds
    }

    return () => clearTimeout(failSafeTimer);
  }, [isLoadingSmall]);


  const handleGeolocate = (e) => {
    console.log("handleGeolocate...", e);

    const currentTime = e.timestamp;
    if (startTime && (currentTime - startTime > 1000)) {
      console.log("Turning off LOading");
      setIsLoadingSmall(false);
      setStartTime(null); // Reset start time
    }
  };

  const handleTrackUserLocationStart = (e) => {
    console.log("Starting geolocating");
    setIsLoadingSmall(true);
    setStartTime(Date.now());
  };

  const handleError = (error) => {
    console.error("Geolocation error:", error);
    setIsLoadingSmall(false); // Stop loading on error
    setStartTime(null); // Reset start time
  };


  ////////////////////////

  // User defaults

  useEffect(() => {
    // Dangerously set user checks from local storage. If one exist, userChecks is true and all other will be null.
    const userChecks = JSON.parse(localStorage.getItem("mainSettings"));
    console.log("userChecks", userChecks, "sV", sliderValue)
    if (userChecks) {      
      setSpottersCheck(userChecks.default_spotters ? userChecks.default_spotters === 'spotters' : spottersCheck);
      setSpotterFavoritesCheck(userChecks.default_spotters ? userChecks.default_spotters === 'favorites' : spotterFavoritesCheck);
      setTornadoReportsCheck(userChecks.default_tornado_reports ?? tornadoReportsCheck);
      setRadarCheck(userChecks.default_radar ?? radarCheck)
      setSatelliteCheck(userChecks.default_satellite ?? satelliteCheck)
      setHailReportsCheck(userChecks.default_hail_reports ?? hailReportsCheck)
      setWindReportsCheck(userChecks.default_wind_reports ?? windReportsCheck)
      setOtherReportsCheck(userChecks.default_other_reports ?? otherReportsCheck)
      setWarningsCheck(userChecks.default_warnings ?? warningsCheck)
      setTvChannelsCheck(userChecks.default_tv_channels ?? tvChannelsCheck)
      setWatchesCheck(userChecks.default_watches ?? watchesCheck)
      setLegendChecks(userChecks.default_legend ?? legendChecks)
      setSliderValue(userChecks.default_slider ? [userChecks.default_slider, 0] : sliderValue)
      setMapStyle(userChecks.default_map_style ?? mapStyle)
      setTimeFormat(Number(userChecks.default_time_indicator ?? timeFormat))
      
      setUserChecksLoaded(true);
    }
    //console.log("userChecks", userChecks)
    
  },[])  

  // Carousel settings
  const [sliderSettings, setSliderSettings] = useState({});

  const xsml = useMediaQuery("(max-width:380px)");
  const sml = useMediaQuery("(min-width:381px)");
  const isTab = useMediaQuery("(min-width:601px)");
  const isMobile = useMediaQuery("(max-width:700px)");
  const isLrg = useMediaQuery("(max-width: 1000px)");

  useEffect(() => {
    setSliderSettings({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: xsml ? 1 : sml && isMobile ? 2 : isTab && isLrg ? 3 : 5,
      slidesToScroll: 1,
      autoplay: true,
      className:
        "relative ml-7 w-[calc(100%-55px)] [&>div>div]:flex [&>div>div]:justify-center",
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    });
  }, [isMobile, isLrg, isTab, xsml]);

  const NextArrow = ({ className, onClick }) => {
    return (
      <div
        className={`${className} absolute !-right-6 sm:right-4  !top-1/2`}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = ({ className, onClick }) => {
    return (
      <div
        className={`${className} absolute !-left-6 !top-1/2`}
        onClick={onClick}
      />
    );
  };  

  ////////////////////////
  //////// LAYERS //////// 
  ////////////////////////

  // Spotters
  useEffect(() => {
    if (spottersCheck || spotterFavoritesCheck) {
      // Remember favorites may not be 'active' as in driving x miles! Will appear as arcs, but not scatterplot
      const spotterLayer = createSpottersLayer({
        activeSpotters,
        spottersCheck,
        spotterFavoritesCheck,
        displayPopup,
        setModalType,
      });
      setSpotterLayer(spotterLayer);
    } else {
      setSpotterLayer(null);
    }
  }, [activeSpotters, spottersCheck, spotterFavoritesCheck, displayPopup, setModalType]);


  // Spotter Reports
  useEffect(() => {
    const loadSpotterReportsLayer = async () => {
      if (otherReportsCheck) {
        const spotterReportsLayer = createSpotterReportsLayer({
          spotterReports,
          otherReportsCheck,
          displayPopup,
          zoomToPositionAndTime,
          setModalType,
        });
        setSpotterReportsLayer(spotterReportsLayer);
      } else {
        setSpotterReportsLayer(null); // Optionally set to null or keep it loaded based on your requirements
      }
    };

    loadSpotterReportsLayer();
  }, [otherReportsCheck, spotterReports, displayPopup, zoomToPositionAndTime, setModalType]);
  

  // TV Channels
  const tvChannelLayer = useMemo(() => {
    if (tvChannelsCheck) {
      console.log("Creating tvChannel layer", tvChannels.length);
      return createTvChannelsLayer({
        tvChannels,
        tvChannelsCheck,
        displayPopup,
        setModalType
      });
    }
    return null;
  }, [tvChannels, tvChannelsCheck]);
  


  // SPC REPORTS: Tornado Layer
//  console.log("STORMMAP spcTornadoReports", new Date().toISOString(), spcTornadoReports)
  useEffect(() => {
    const loadTornadoReportsLayer = async () => {
      if (tornadoReportsCheck) {
        const tornadoLayer = createTornadoReportsLayer({
          layer_name: 'tornado-reports-layer',
          spcTornadoReports,
          tornadoReportsCheck,
          displayPopup,
          zoomToPositionAndTime,
          setModalType,
        });
        setTornadoReportsLayer(tornadoLayer);
      } else {
        setTornadoReportsLayer(null); // Optionally set to null or keep it loaded based on your requirements
      }
    };

    loadTornadoReportsLayer();
  }, [tornadoReportsCheck, spcTornadoReports, displayPopup, zoomToPositionAndTime, setModalType]);

  // SPC REPORTS: Hail Layer
  //console.log("spcHailReports", spcHailReports)
  useEffect(() => {
    const loadHailReportsLayer = async () => {
      if (hailReportsCheck) {
        const hailLayer = createHailReportsLayer({
          layer_name: 'hail-reports-layer',
          spcHailReports,
          hailReportsCheck,
          displayPopup,
          zoomToPositionAndTime,
          setModalType,
        });
        setHailReportsLayer(hailLayer);
      } else {
        setHailReportsLayer(null); // Optionally set to null or keep it loaded based on your requirements
      }
    };

    loadHailReportsLayer();
  }, [hailReportsCheck, spcHailReports, displayPopup, zoomToPositionAndTime, setModalType]);
  

  // SPC REPORTS: Wind Layer
  useEffect(() => {
    const loadWindReportsLayer = async () => {
      if (windReportsCheck) {
        const windLayer = createWindReportsLayer({
          layer_name: "wind-reports-layer",
          spcWindReports,
          windReportsCheck,
          displayPopup,
          zoomToPositionAndTime,
          setModalType,
        });
        setWindReportsLayer(windLayer);
      } else {
        setWindReportsLayer(null); // Optionally set to null or keep it loaded based on your requirements
      }
    };

    loadWindReportsLayer();
  }, [windReportsCheck, spcWindReports, displayPopup, zoomToPositionAndTime, setModalType]);


  // Outlooks Categorical layer
  const outlookDaysCategoricalLayer = createOutlookDaysCategoricalLayer({
    outlookDaysData,
    checkOutlookDays,
    displayPopup,
    setModalType
  })


  // Outlooks Tornado Layer
  const outlookDaysTornadoLayer = createOutlookDaysTornadoLayer({
    outlookDaysData,
    checkOutlookDays,
    displayPopup,
    setModalType
  })


  // WARNINGS
  const warningLayer = [];

  // TORNADO WARNINGS
  warningLayer.push(createTornadoWarningLayers({
    tornadoWarnings,
    warningsCheck,
    displayPopup,
    setModalType
  }))

  // SEVERE WARNINGS
  warningLayer.push(createSevereWarningLayers({
    severeWarnings,
    warningsCheck,
    displayPopup,
    setModalType

  }))


  /// WATCHES
  const watchLayer = [];

  // SEVERE WATCH
  watchLayer.push(createSevereWatchLayers({
    severeWatches,
    watchesCheck,
    displayPopup,
    setModalType
  }))

  // TORNADO WATCH
  watchLayer.push(createTornadoWatchLayers({
    tornadoWatches,
    watchesCheck,
    displayPopup,
    setModalType
  }))




  // SPOTTER TRAILS

  // Activate on chase
  // const arcLayerChristofferPos = new ArcLayer({
    
  //   id: 'arc-layer-christoffer',
  //   data: christofferPositions,
  //   pickable: true,
  //   getWidth: 5,
  //   getSourcePosition: (d) => d.source,
  //   getTargetPosition: (d) => d.target,
  //   getSourceColor: (d) => {
  //     return getArcOpacityAndColor(d, 'source')
  //   },
  //   getTargetColor: (d) => {
  //     return getArcOpacityAndColor(d, 'target')
  //   },
  // });
// Christoffer storm chasing positions
useEffect(() => {
  const queryParams = new URLSearchParams(location.search);

  let isMounted = true; // To handle component unmounting

  async function fetchAndSetArcData() {
    if (!isMounted) return;

    if (spotterFavoritesCheck && spotterFavorites && spotterFavorites.length > 0) {
      const favoritesPositions = await mapService.getSpotterPositions({
        spotters: spotterFavorites,
        upperTime: calculateIsoTimeFromSlider(0),
        lowerTime: calculateIsoTimeFromSlider(-24)
      });
      setAllFavoritesPositions(favoritesPositions);
    }
  }

  fetchAndSetArcData(); // Initial call

  const intervalId = setInterval(() => {
    fetchAndSetArcData();
  }, 60 * 1000); // Refresh every minute

  return () => {
    clearInterval(intervalId);
    isMounted = false;
  };
}, [spotterFavoritesCheck, spotterFavorites]);

// Update layer when data or slider changes
useEffect(() => {
  const updateArcLayer = () => {
    if (spotterFavoritesCheck && allFavoritesPositions.length > 0) {
      const filteredFavoritesPositions = allFavoritesPositions.filter(position => {
        const positionTime = new Date(position.last_ping_utc).getTime();
        const lowerTime = new Date(calculateIsoTimeFromSlider(sliderValue[0])).getTime();
        const upperTime = new Date(calculateIsoTimeFromSlider(sliderValue[1])).getTime();
        return positionTime >= lowerTime && positionTime <= upperTime;
      });

      const favoritesArcs = separateSpotterArcs(filteredFavoritesPositions);
      setFavoritesPositions(favoritesArcs);

      const arcLayer = createArcLayerSpotterPos({
        favoritesPositions: favoritesArcs,
        spotterFavoritesCheck,
      });
      setArcLayerSpotterPos(arcLayer);
    } else {
      setArcLayerSpotterPos(null);
    }
  };

  updateArcLayer();
}, [allFavoritesPositions, spotterFavoritesCheck, sliderValue]); // Does not need to be buffered. No backend fetches.
  

// Radar layer
const radarLayer = useMemo(() => 
  radarCheck ? createRadarLayer({ 
    sliderValue, customTimestampTransform, radarCheck 
  }) : null, [radarCheck, bufferedSliderValue]
);
  

// Satellite layer
const satelliteData = () => {
  console.log("SatelliteData fetch")
  let useUrl;
  if (sliderValue[1] === 0) {
    useUrl =
      "https://mesonet.agron.iastate.edu/cache/tile.py/1.0.0/goes-east-vis-1km-900913/{z}/{x}/{y}.png?" +
      new Date().getTime();
  } else {
    useUrl =
      "https://realearth.ssec.wisc.edu/api/image?products=globalvis_" +
      customTimestampTransform("realearth") +
      "&x={x}&y={y}&z={z}&accesskey=e789fdf0d274444b8bd4be1467d7d697";
  }
  return useUrl;
};


const satelliteLayer = useMemo(() => 
  satelliteCheck ? createSatelliteLayer({ 
    data: satelliteData(), satelliteCheck 
  }) : null, [satelliteCheck, bufferedSliderValue]
);


  //////// END LAYERS ///////////
 

  // OUTLOOK FUNCTIONS

  // What is item? This loads SPC Outlook in topbar.
  useEffect(() => {
    async function fetchOutlooks() {
      const data = await outlookService.getSpcOutlooks();
      transformSPCOutlookData(data);

      setOutlookDaysData(data[1]);
    }

    fetchOutlooks();
  }, []);


  const transformSPCOutlookData = (data) => {
    const recentOutlookCategoricalCount = data[0]["categorical"].length;
    const recentOutlookTornadoCount = data[0]["tornado"]?.length || 0;
    const day1OutlookCategoricalCount = data[1]["categorical"].length;
    const day1OutlookTornadoCount = data[1]["tornado"]?.length || 0;
    const day2OutlookCategoricalCount = data[2]["categorical"].length;
    const day2OutlookTornadoCount = data[2]["tornado"]?.length || 0;
    const day3OutlookCategoricalCount = data[3]["categorical"].length;
    const day3OutlookTornadoCount = data[3]["tornado"]?.length || 0;
    const retData = [
      {
        ...data[0]["categorical"][recentOutlookCategoricalCount - 1][
          "properties"
        ],
        DN:
          recentOutlookTornadoCount > 0
            ? data[0]["tornado"][recentOutlookTornadoCount - 1]["properties"][
                "DN"
              ]
            : 0,
      },
      {
        ...data[1]["categorical"][day1OutlookCategoricalCount - 1][
          "properties"
        ],
        DN:
          day1OutlookTornadoCount > 0
            ? data[1]["tornado"][day1OutlookTornadoCount - 1]["properties"][
                "DN"
              ]
            : 0,
      },
      {
        ...data[2]["categorical"][day2OutlookCategoricalCount - 1][
          "properties"
        ],
        DN:
          day2OutlookTornadoCount > 0
            ? data[2]["tornado"][day2OutlookTornadoCount - 1]["properties"][
                "DN"
              ]
            : 0,
      },
      {
        ...data[3]["categorical"][day3OutlookCategoricalCount - 1][
          "properties"
        ],
        DN:
          day3OutlookTornadoCount > 0
            ? data[3]["tornado"][day3OutlookTornadoCount - 1]["properties"][
                "DN"
              ]
            : 0,
      },
    ]
    //console.log("SPC OUTLOOK DATA", retData)
    setSpcOutlooks(retData);
  };

  useEffect(() => {
    async function fetchOutlooks() {
      // console.log("Fetching outlooks from backend...");
      const data = await outlookService.getSpcOutlooks();
      //console.log("checkOutlookDays", data)
      setOutlookDaysData(data[1]);
      if (outlookDay === "day1") {
        setOutlookDaysData(data[1]);
        //setCheckOutlookDays(!checkOutlookDays) // Should switch off if clicked again?
        setCheckOutlookDays(true);
      } else if (outlookDay === "day2") {
        setOutlookDaysData(data[2]);
        setCheckOutlookDays(true);
      } else if (outlookDay === "day3") {
        setOutlookDaysData(data[3]);
        setCheckOutlookDays(true);
      } else if (outlookDay === "recently") {
        setOutlookDaysData(data[0]);
        setCheckOutlookDays(true);
      } else {
        setCheckOutlookDays(false);
      }
    }

    fetchOutlooks();
  }, [outlookDay]);


  function separateSpotterArcs(positions) {
    //console.log("POSITIONS", positions)
    // Group positions by spotter_id
    const positionsBySpotter = positions.reduce((acc, position) => {
        acc[position.spotter_id] = acc[position.spotter_id] || [];
        acc[position.spotter_id].push(position);
        return acc;
    }, {});

    //console.log("POSITIONSBYSPOTTER", positionsBySpotter)
    
    // Generate pairs of positions for arcs
    const arcsData = [];
    Object.keys(positionsBySpotter).forEach(spotter_id => {
        const spotterPositions = positionsBySpotter[spotter_id];
        // Sort positions by last_ping_utc if needed
        spotterPositions.sort((a, b) => new Date(a.last_ping_utc) - new Date(b.last_ping_utc));
        for (let i = 0; i < spotterPositions.length - 1; i++) {
            //console.log("GETNAME",spotterPositions[i])
            const source = spotterPositions[i];
            const target = spotterPositions[i + 1];
            arcsData.push({
                id: `${spotter_id}_${i}`,
                trail_color: target.trail_color,
                source: [source.longitude, source.latitude],
                target: [target.longitude, target.latitude],
                spotter_id: spotter_id,  // include this if you need to use the spotter_id in styling or filtering
                spotter_name: `${target.sn_first_name} ${target.sn_last_name}`,
                timestamp: `${timeSince(target.last_ping_utc)} ago`,
                index: spotterPositions.length - i +1,
                arc_length: spotterPositions.length,
                map_item: "arc"
            });
        }
    });  
    return arcsData;
  }    

  // Spotter functions
  useEffect(() => {
    async function getSpotters() {
      if (userChecksLoaded && (spottersCheck || spotterFavoritesCheck)) {
        // console.log("Getting Spotter from backend...", spottersCheck, spotterFavoritesCheck);
        //console.log(calculateIsoTimeFromSlider(sliderValue[1]))
        const data = await spotterService.getSpottersByTime({
            upperTime: calculateIsoTimeFromSlider(sliderValue[1]),
            lowerTime: calculateIsoTimeFromSlider(sliderValue[0]),
        }); 

        let spottersFiltered = data
          .filter((spotter) => {
            return (
              spotter.last_ping_utc >=
              new Date(
                now.getTime() - -sliderValue[0] * 60 * 60 * 1000
              ).toISOString()
            );
          })
          .filter((spotter) => {
            return (
              spotter.last_ping_utc <=
              new Date(
                now.getTime() - -sliderValue[1] * 60 * 60 * 1000
              ).toISOString()
            );
          })
          .filter((spotter) => {
            //console.log(typeof(spotter.id), typeof(spotterFavorites[0]))
            return (
              spotterFavoritesCheck ? spotterFavorites.includes(spotter.id) : true
            )
          }) // Filter based on showOnlySpotters
        //console.log("FILTER", spotterFavoritesCheck, "spottersFiltered", spottersFiltered, "spotterFavorites", spotterFavorites )
        const spotters = spottersFiltered.map((spotterData) => {
          return {
            ...spotterData,
            position: [spotterData.sn_longitude, spotterData.sn_latitude],
          };
        });
        const activeSpotters = spotters.filter((spotter) => { return spotter.distance_last_24h > 50})      
        setSpotters(spotters);
        setActiveSpotters(activeSpotters);
      }
    }

    getSpotters(); //Initial call
    
    const timeoutId = setTimeout(() => {
      getSpotters();
    }, 60*1000); // Refresh every minute
    return () => {
      clearTimeout(timeoutId);
    };
  }, [bufferedSliderValue, spottersCheck]);

  //console.log("SPOTTERS", activeSpotters)


  // POPUP / MODAL HANDLING
  const handleCloseModal = (e) => {
    //e.stopPropagation();
    //e.preventDefault();
    setIsModalOpen(false);
  };

  const handleHideLayers = () => {
    setIsModalOpen(false);
    switch (modalType) {
      case "Warning":
        setWarningsCheck(false);
        break;
      case "Watches":
        setWatchesCheck(false);
        break;
      case "TVChannel":
        setTvChannelsCheck(false);
        break;
      case "Outlook":
        setOutlookDay("off")
        break;
      case "TornadoReports":
        setTornadoReportsCheck(false);
        break;
      case "HailReports":
        setHailReportsCheck(false);
        break;
      case "WindReports":
        setWindReportsCheck(false);
        break;
      case "OtherReports":
        setOtherReportsCheck(false);
        break;
      case "Spotter":
        setSpottersCheck(false);
        setSpotterFavoritesCheck(false);
        break;
      default:
        break;
    }
  }

  const handleButtonClick = () => {
    document.getElementsByClassName('mapboxgl-ctrl-geolocate')[0]?.click()
  }

  const mapRef = useRef(null);

  const handleMapLoad = useCallback(() => {
    if (mapRef.current) {
      mapInstance.current = mapRef.current.getMap();
      if (location.state && location.state.targetLocation) {
        const { lat, lng, relativeTime } = location.state.targetLocation;
        zoomToPositionAndTime(lat, lng, relativeTime);
        navigate('/', { replace: true, state: {} });
      }
      setIsMapLoaded(true);  // Set map as loaded
    }
  }, [mapInstance, location.state, navigate, zoomToPositionAndTime]);

  const handleViewportChange = useCallback((event) => {
    setViewport(event.viewState);
  }, []);

  useEffect(() => {
    const checkScreenSize = () => {
      const targetElements = document.getElementsByClassName('mapboxgl-ctrl mapboxgl-ctrl-group');
     if(targetElements.length > 0) 
      {
        for(const element of targetElements) {
          element.style.display = window.innerWidth > 640? 'block': 'none'}
      }}
   setTimeout(() => {
    checkScreenSize();
   },400)
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // // Tweet photos
  // const positionedPhotosLayer = createPositionedPhotosLayer({
  //   data: positionedStormPhotos,
  //   tweetCheck,
  //   displayTweetPopup,
  // })

  // // Tweets: general
  // const tweetsLayer = createTweetsLayer({
  //   data: positionedTweets,
  //   tweetCheck,
  //   displayTweetPopup,
  // })

  // // Tweet videos
  // const videoLayer = createVideoLayer({
  //   data: positionedVideos,
  //   tweetCheck,
  //   displayTweetPopup,
  // })    

  /* 
  // Set upper slider to Live
  const getRelativeStringFromRelative = (relative) => {
    if (relative === 0) {
      return "Live";
    } else {
      return relative + " h";
    }
  };*/

  /////// MAP SECTION ///////
  return (
    <>
    <div className="flex flex-col">
      <Helmet>
        <title>Live & 24h Storm chasing Map - StormCenter</title>
        <meta
          name="description"
          content="StormCenter is a live storm chasing map. You can track spotters, see all storm and tornado reports and watch storms unfold live. See last 24h of storms or check out every storm day in the last two decades."
        />
      </Helmet>
      {isLoadingSmall && (
        <Suspense fallback={<div>Loading your location...</div>}>
          <LoaderSmall />
        </Suspense>
      )}
      <div className={"relative flex justify-center !h-[calc(100vh-62px)]"}>
        <Suspense fallback={<Loader />}>
          <Map
            mapStyle={selectableMapStyles[mapStyle]}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
            ref={mapRef}
            onLoad={handleMapLoad}
            onClick={(e) => e.preventDefault()}
            onDrag={handleViewportChange}
            onMoveEnd={handleViewportChange}
            style={{ position: "relative", height: "100%", width: "100%" }}
            initialViewState={viewport}
          >
            <DeckGLOverlay
            layers={useMemo(() => [
              
              outlookDaysCategoricalLayer,
              outlookDaysTornadoLayer,
              watchLayer,
              warningLayer,
              spotterLayer,
              spotterReportsLayer,
              
              windReportsLayer,
              hailReportsLayer,
              tornadoReportsLayer,
              tvChannelLayer,
              radarLayer,
              satelliteLayer,
              arcLayerSpotterPos,
            ], [
              outlookDaysCategoricalLayer,
              outlookDaysTornadoLayer,
              watchLayer,
              warningLayer,
              spotterLayer,
              spotterReportsLayer,
              arcLayerSpotterPos,
              windReportsLayer,
              hailReportsLayer,
              tornadoReportsLayer,
              tvChannelLayer,
              radarLayer,
              satelliteLayer              
            ])}            
              style={{ position: "relative", height: "100%", width: "100%" }}
              controller={{ dragRotate: false }}
              getCursor={() => cursor}
              onClick={(e) => {
                console.log(e);
              }}
              onHover={(event) => {
                setCursor(event.object ? "pointer" : "grab");
              }}
              getTooltip={({ object }) => {
                if (object) {
                  const tooltipText = getToolTipFromObject(object);
                  return {
                    html: `<div>${tooltipText}</div>`,
                    style: {
                      color: "white",
                      padding: "5px 10px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
                      textAlign: "center",
                      position: "relative",
                      marginLeft: "10px",
                      marginTop: "-25px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      maxWidth: "220px",
                    },
                  };
                }
              }}
              interleaved={true}
            />
            {(!isMobile || isSearchFieldOpen) && (
              <GeocoderControl
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
                position="top-right"
              />
            )}
            <GeolocateControl
              onGeolocate={handleGeolocate}
              onTrackUserLocationStart={handleTrackUserLocationStart}
              onError={handleError}
              positionOptions={{ enableHighAccuracy: true }}
              trackUserLocation={true}
              onClick={(e) => { console.log("Geolocate clicked"); }}
              showUserHeading={true}
              auto
            />
          </Map>
        </Suspense>
        <div className="bg-white-200 absolute z-50 lg:ml-7 mt-5 group px-2 flex py-2 left-0">
          <Tooltip 
            title="Share on social media" 
            placement="right">
            <div className="bg-gray-100 border-1 border-solid hover:border-transparent border-gray-400 rounded-[25%] shadow-xl">
              <FontAwesomeIcon 
                icon={faShareNodes} 
                className="text-[#4245ff] p-1 w-7 h-6 cursor-pointerl" 
                onClick={(e) => { 
                  displayPopup(
                    <div className='text-black text-center'>
                      <h3 className='text-black mb-4'>Share your opinion about StormCenter!</h3>
                      <div className="flex justify-center space-x-10 ">
                        <FacebookShareButton url='https://stormcenter.app' className="text-lg">
                          <FontAwesomeIcon icon={faFacebook} size="4x" className="text-[#1877F2]" />
                        </FacebookShareButton>
                        <TwitterShareButton url='https://stormcenter.app' className="text-lg">
                          <FontAwesomeIcon icon={faTwitter} size="4x" className="text-[#1DA1F2]" />
                        </TwitterShareButton>     
                        <RedditShareButton url='https://stormcenter.app' className="text-lg">
                          <FontAwesomeIcon icon={faReddit} size="4x" className="text-[#FF4500]" />
                        </RedditShareButton>            
                        <WhatsappShareButton url='https://stormcenter.app' className="text-lg">
                          <FontAwesomeIcon icon={faWhatsapp} size="4x" className="text-[#25D366]" />
                        </WhatsappShareButton>     
                        <EmailShareButton url='https://stormcenter.app' className="text-lg">
                          <FontAwesomeIcon icon={faEnvelope} size="4x" className="text-[#b8b9ba]" />
                        </EmailShareButton>                                        
                      </div>
                    </div>
                  )
                }}
              />
            </div>

          </Tooltip>
        </div>

        {legendChecks && (
          <div className="bg-amber-50 absolute z-50 ml-3 lg:ml-10 mt-20 group px-2 flex py-2 left-0">
            <div className="flex flex-col justify-center h-45 gap-1">
              <div className="h-3 w-3 mt-1 ml-1">
                <div className="red-dot"></div>
              </div>
              <div className="h-3 w-3 mt-1 ml-1">
                <div className="blue-dot"></div>
              </div>
              <TwitterShareButton />
              <img src={getEventIcon('tornado')} alt="tornado icon" className="w-4 h-4" />
              <img src={getEventIcon('tornado_night')} alt="tornado night" className="w-4 h-4" />
              <img src={getEventIcon('tornado_sunset')} alt="tornado sunset" className="w-4 h-4" />
              <img src={getEventIcon('landspout')} alt="landspout icon" className="w-4 h-4" />
              <img src={getEventIcon('hail')} alt="hail icon" className="w-4 h-4" />
              <img src={getEventIcon('wind')} alt="wind icon" className="w-4 h-4" />
              <img src={getEventIcon('wallcloud')} alt="wallcloud icon" className="w-4 h-4" />
              <img src={getEventIcon('funnel')} alt="funnel icon" className="w-4 h-4" />
              <img src={getEventIcon('other')} alt="spotter report icon" className="w-4 h-4" />
            </div>
            <div className="group-hover:flex hidden px-2 flex-col justify-start gap-1 [&>h1]:text-black [&>h1]:text-[10px] [&>h1]:my-[2px]">
              <h1>Spotters</h1>
              <h1>Spotter with Twitter</h1>
              <h1>Tornado report</h1>
              <h1>Tornado (night)</h1>
              <h1>Tornado (sunset)</h1>
              <h1>Landspout tornado report</h1>
              <h1>Hail report</h1>
              <h1>Wind report</h1>
              <h1>Spotter rotating wall cloud report</h1>
              <h1>Spotter funnel cloud report</h1>
              <h1>Spotter general report</h1>
            </div>
          </div>
        )}
      </div>
      <div className={`${isTimeIndicatorOpen ? 'flex' : 'hidden'} bottom-20 sm:flex z-[70] md:left-[25%] left-[18%] w-[70%] justify-center sm:bottom-0 absolute !box-border flex-col items-center space-y-2 bottom-indicators bottom-indicators-${browser}`}>
        <Tooltip
          title="Pull each handle on the slider to select the time window in which to observe storms and items on the map. -12 means 12 hours back in time from now."
          placement="top"
        >
          <Slider
            valueLabelDisplay="auto"
            className={"block"}
            onChange={handleSliderChange}
            value={sliderValue}
            step={0.1}
            min={-24}
            max={0}
          />
        </Tooltip>
        <div>
          <SliderTimeIndicator />
        </div>
      </div>
      {isModalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50"
          onClick={handleCloseModal}
        >
          <div
            className="relative w-11/12 md:w-1/2 bg-white rounded-lg shadow-lg p-4 md:max-h-[85%] max-h-[65%] overflow-y-auto pb-5 mx-2"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-end mb-3">
              <img
                src={hideLayerIcon}
                alt="hide layer"
                className="text-blue-900 text-lg cursor-pointer w-7 h-7 mr-5"
                onClick={handleHideLayers}
              />
              <FontAwesomeIcon
                icon={faXmark}
                className="text-blue-900 text-lg cursor-pointer w-7 h-7"
                onClick={handleCloseModal}
              />
            </div>
            {modalComponent}
          </div>
        </div>
      )}
    </div>
      <div
      className={`w-full block sm:hidden z-[9999] bottom-0 absolute bottom-indicators bottom-indicators-${browser}`}
    >
      <div className="grow bg-[#102d3e] flex flex-1 items-center h-20 p-3 justify-between px-3">
        <FontAwesomeIcon
          ref={layerGroupRef1}
          icon={faLayerGroup}
          className="text-slate-100 w-[8vw] h-[8vw] cursor-pointer"
          onClick={() => {
            setIsRightSidebarOpen(false)
            setIsSidebarOpen(prev => {
              setIsLayerMenuOpen(prev ? false : true)
              return !prev;
            })
            setIsTopbarOpen(false)
            setIsSpotterMenuOpen(false)
          }}
        />
        <FontAwesomeIcon
          icon={faSlidersH}
          onClick={() => setIsTimeIndicatorOpen(!isTimeIndicatorOpen)}
          className="text-slate-100 w-[8vw] h-[8vw] cursor-pointer"
        />
        <FontAwesomeIcon
          icon={faCrosshairs}
          onClick={handleButtonClick}
          className="text-slate-100 w-[8vw] h-[8vw] cursor-pointer"
        />
        <FontAwesomeIcon
          icon={faSearch}
          onClick={() => setIsSearchFieldOpen(!isSearchFieldOpen)}
          className="text-slate-100 w-[8vw] h-[8vw] cursor-pointer"
        />
        <FontAwesomeIcon
          ref={layerGroupRef2}
          icon={faCar}
          className="text-slate-100 w-[8vw] h-[8vw] cursor-pointer"
          onClick={() => {
            setIsRightSidebarOpen(false)
            setIsSidebarOpen((prev) => {
              setIsSpotterMenuOpen(prev ? false : true)
              return !prev;
            })
            setIsTopbarOpen(false)
            setIsLayerMenuOpen(false)
          }}
        />
        <FontAwesomeIcon
          icon={faCog}
          className="text-slate-100 w-[8vw] h-[8vw] cursor-pointer"
          onClick={() => navigate('/settings')}
        />
      </div>
    </div>
    </>    
  );
  
}

export default StormMap;