import React, { useRef, useEffect, useState, useContext } from 'react'
import { Helmet } from "react-helmet";

import { StormContext } from "contexts/StormContext";
import { SpotterRow } from 'components/spotters/SpotterRow'
import Loader from "components/loader-gifs/Loader";
import SpotterService from "services/SpotterService";
import { useAuth } from 'contexts/AuthContext';
import { useToggleFavorite } from 'hooks/useToggleFavorite';

function Spotters() {
    const inputRef = useRef(null);
    const { state } = useAuth();
    const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
    const [lastName, setLastName] = useState('');
    const [activeList, setActiveList] = useState("all"); // Not used atm but useful for refactoring
    const [spottersList, setSpottersList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    //const [resetTrigger, setResetTrigger] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const favoritesButtonText = showOnlyFavorites ? "Show all" : "Show favorites"; // Shows the opposite of course
    //const [searchText, setSearchText] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);
    const [continueGettingData, setContinueGettingData] = useState(true);

    const spotterService = new SpotterService();
    const contentRef = useRef()
    const [spotterFavoritesFull, setSpotterFavoritesFull] = useState([]);

    //console.log("OVER show favorite", showOnlyFavorites, "text", favoritesButtonText)
    //console.log("spotterFavoritesFulls", spotterFavoritesFull)
    const {
        spotterFavorites,
    } = useContext(StormContext);

    const { toggleSpotterFavorite } = useToggleFavorite();

    const handleSearchBoxChange = async (event) => {
        const text = event.target.value.trim();
        if (text.length > 2) { // Not DRY
            // Execute the search function here
            //console.log("Running search by last name")
            if (activeList !== 'search') {
                console.log("66 search")
                setActiveList("search")
            }
            setShowOnlyFavorites(false)
            setLastName(event.target.value)
        }
    }

    const searchSpottersByName = async () => {
        const response = await spotterService.searchSpotters({
            name: lastName,
            limit: 50,
            offset: (currentPage) * 50
        });
        //console.log("REST searchSpottersByName response", response)
        return response
    }

    const getAllSpotters = async () => {
        const response = await spotterService.getAllSpotters({
            limit: 50,
            offset: (currentPage) * 50
        });
        console.log(currentPage, "REST getAllSpotters response", response)
        return response
    }

    async function showDefaultList() {
        // if (activeList !== 'all') {
        //     console.log("95 all currpage", currentPage)
        //     setActiveList("all")
        // }
        setIsLoading(true);
        const filteredSpotters = await getAllSpotters()
        //console.log("Running get all")
        if (filteredSpotters.length === 0) {
            setContinueGettingData(false);
        } else {
            // Hotfix to avoid duplicates. Still issue with proper ordering.
            setSpottersList(prevSpotters => {
                const spotterIds = new Set(prevSpotters.map(spotter => spotter.id)); // Assuming each spotter has a unique 'id'
                const newSpotters = filteredSpotters.filter(spotter => !spotterIds.has(spotter.id));
                return [...prevSpotters, ...newSpotters];
            });
        }
        console.log("filteredSpotters", filteredSpotters)

        setIsLoading(false);
    }

    // Reset search
    function resetLastName() {
        console.log("Resetting last name")
        inputRef.current.value = '';
        if (activeList !== 'all') {
            console.log("95 all")
            setActiveList("all")
        }
        setShowOnlyFavorites(false)
        setLastName("");
        setCurrentPage(0)
        showDefaultList()
        //setResetTrigger(prev => !prev); // Toggle the reset trigger
    }

    function toggleOnlyFavorites() {
        //console.log("---showOnlyFavorite BEFORE:", showOnlyFavorites)
        let newShowOnlyFavoritesValue = !showOnlyFavorites
        setShowOnlyFavorites(newShowOnlyFavoritesValue)
        if (newShowOnlyFavoritesValue) {
            console.log("setting to favorites")
            if (activeList !== 'favorites') { setActiveList("favorites") }
        } else {

            if (activeList !== 'all') {
                console.log("115 setting to all")
                setActiveList("all")
            }
            //setResetTrigger(prev => !prev)
        }
        console.log("---showOnlyFavorites:", newShowOnlyFavoritesValue)
    }

    useEffect(() => {
        if (activeList === "favorites" && spotterFavorites.length > 0) {
            const getSpotterFavorites = async () => {
                //console.log("Getting favorites", spotterFavorites)
                const response = await spotterService.getFavorites({
                    ids: spotterFavorites
                })
                // console.log("Favorites response", response)
                setSpotterFavoritesFull(response)
                //console.log("spotterFavoritesFull", spotterFavoritesFull)
            }
            getSpotterFavorites()
        } else {
            showDefaultList()
        }
    }, [activeList]);

    //console.log("Active list", activeList)

    // Last name search
    useEffect(() => {
        async function search() {
            let filteredSpotters = await searchSpottersByName()
            setIsLoading(true);
            //console.log("filteredSpotters", filteredSpotters)
            if (lastName.length === 0) {
                // Normal listing. Only with last_ping_.utc  

                console.log("--- Normal listing ")
                const currentTimeLimitUpper = new Date(Date.now())
                const currentTimeLimitLower = new Date(Date.now() - 24 * 60 * 60 * 1000);
                filteredSpotters = filteredSpotters.filter((spotter) => {
                    return spotter.last_ping_utc !== null
                })
                //console.log("NONNULL spotters", filteredSpotters)    
                filteredSpotters = filteredSpotters.filter((spotter) => {
                    return (new Date(spotter.last_ping_utc) > currentTimeLimitLower && new Date(spotter.last_ping_utc) <= currentTimeLimitUpper)
                })

                filteredSpotters.sort(function (a, b) {
                    return b.last_ping_utc.localeCompare(a.last_ping_utc)
                })
                //console.log("REALLY filteredSpotters", filteredSpotters)

            } else if (lastName.length > 2) {
                if (activeList !== 'search') {
                    console.log("169 Setting active list to search")
                    setActiveList("search")
                }
                console.log("--- SEARCH NAME listing ", lastName)
                // Search results by name
                filteredSpotters.sort((a, b) => {
                    return b.lastSnPing ? 1 : -1; // This line sorts based on the existence of `lastSnPing`
                });
            }
            setSpottersList(filteredSpotters);
            setIsLoading(false);
            setContinueGettingData(true);
            setCurrentPage(0);
        }
        search()

    }, [activeList, lastName]);


    // Scroll display
    useEffect(() => {
        (async () => {
            //console.log("Scrolling...", currentPage)
            await showDefaultList()
        })();
    }, [currentPage]);

    // Startup
    useEffect(() => {
        (async () => {
            await showDefaultList()
        })();
    }, []);

    // Startup
    useEffect(() => {
        (async () => {
            if (activeList === 'all') {
                console.log("207 all")
                await showDefaultList()
            }
        })();
    }, [activeList]);


    useEffect(() => {
        const element = contentRef.current;

        if (!element) { return; }

        function handleScroll() {
            const { scrollTop, clientHeight, scrollHeight } = element;
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                if (continueGettingData && !isLoading) {
                    setScrollPosition(scrollTop);
                    setCurrentPage((prevPage) => prevPage + 1);
                    setIsLoading(true);
                }
            }
        }

        element.addEventListener("scroll", handleScroll);
        return () => {
            element.removeEventListener("scroll", handleScroll);
        };
    }, [continueGettingData, isLoading]);




    return (<div className='mx-auto px-4 md:px-8 h-full overflow-y-scroll' ref={contentRef}>
        <Helmet>
            <title>Storm chasers in the USA</title>
            <meta name="description" content="All storm chasers in the USA that have been active in the last 24 hours." />
        </Helmet>
        <div className='pb-10'>
            <h1 className=''>Find a storm spotter on the map</h1>
            <p>
                Here you can search for a storm chaser (storm spotter) on the map. You can only find registered spotters
                that have been active (i.e. made a SpotterNetwork ping) in the last 24 hours. This means that you will
                only see chasers who 1) Is a registered storm spotter, 2) Have the storm spotter beacon activated, 3)
                Have activated the beacon (pinged) in the last 24 hours.
            </p>
            <div>
                <input
                    type="text"
                    placeholder='Enter last name'
                    className='p-1'
                    onChange={(e) => handleSearchBoxChange(e)}
                    ref={inputRef}
                />
                <button type="submit" className="cursor-pointer" onClick={resetLastName}>Reset</button>

            </div>

            <div className="pb-6 ">
                <h3>{showOnlyFavorites ? "Favorite" : "Active"} chasers {lastName && <span>for search result: {lastName}</span>}
                    { state.isAuthenticated && state.user &&
                        <button className="text-base cursor-pointer" type="submit" onClick={toggleOnlyFavorites} id="onlyFavoritesButton">{favoritesButtonText}</button>
                    }
                </h3>
                <div className=" overflow-x-auto">
                    <table className='w-full table border-collapse border-spacing-0.5 bg-[#012432ba] rounded-md'>
                        <thead>
                            <tr className='bg-transparent  text-white border-solid border-0 border-b border-[#ffffff54]'>
                                {
                                    state.isAuthenticated && state.user && 
                                    <th className='font-bold text-left p-[8px] w-[20px]'></th>
                                }
                                <th className='font-bold text-left p-[8px] min-w-[100px] sm:min-w-[150px] lg:min-w-[200px]'>
                                    Name
                                </th>
                                <th className='font-bold text-left p-[8px] sm:min-w-[50px] lg:min-w-[120px]'>
                                    YouTube
                                </th>                                
                                <th className='font-bold text-left p-[8px] sm:min-w-[120px] lg:min-w-[200px]'>
                                    Twitter
                                </th>
                                <th className='font-bold text-left p-[8px] min-w-[200px] lg:min-w-[300px]'>
                                    Last SN ping
                                </th>
                                <th className='font-bold text-left p-[8px] min-w-[130px] lg:min-w-[150px]'>
                                    Last known position
                                </th>
                                <th className='font-bold text-left p-[8px] min-w-[130px] lg:min-w-[150px]'>
                                    Miles last 24h
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                //console.log("Favorites full", spotterFavoritesFull)
                            }
                            {isLoading ? (<tr key='loader'>
                                <td className='p-[8px] align-baseline text-center' colSpan="7">
                                    <Loader />
                                </td>
                            </tr>) : (showOnlyFavorites ? spotterFavoritesFull : spottersList).length > 0 ?
                                (showOnlyFavorites ? spotterFavoritesFull : spottersList)
                                    //.filter(item => showOnlyFavorites ?  spotterFavoritesFull : true) // Filter based on showOnlySpotters
                                    .map((item, idx) =>
                                        <>

                                            <SpotterRow
                                                spotterId={item.spotter_id}
                                                name={item?.sn_first_name}
                                                lastName={item?.sn_last_name}
                                                twitter={item?.twitter ? `@${item?.twitter}` : ""}
                                                youtube_channel={item?.youtube_channel}
                                                youtube_active_livestream={item?.youtube_active_livestream}
                                                lastSnPing={item?.last_ping_utc ?? ""}
                                                lastPosition={item?.location_state ?? ""}
                                                lat={item?.sn_latitude ?? ""}
                                                lng={item?.sn_longitude ?? ""}
                                                toggleSpotterFavorite={toggleSpotterFavorite}
                                                isFavorite={spotterFavorites.includes(item.spotter_id)}
                                                key={`${item.spotter_id}_${idx}`}
                                                distanceCovered={item.distance_last_24h}
                                            />
                                        </>
                                    ) : <tr className='bg-transparent text-white border-solid border-0 border-b border-[#ffffff54]'>
                                    <td key='noneFound' className='p-[8px] align-baseline text-center' colSpan="7">
                                        No spotter found
                                    </td>
                                </tr>}


                        </tbody>
                    </table>
                </div>
                <p>
                    Note: This list is not a complete list of storm
                    chasers. It is a list of spotters who have been active in the last 24 hours. This also means that if
                    you search for a spotter at one time (when he or she is active) you may not get the same result another
                    day.
                </p>
            </div>
        </div>
    </div>)
}

export default Spotters