export const utcDateToReadable = (utcTimestamp, timezone = 'zulu', seconds = false) => {
    try {
        if (timezone === 'zulu') {
            let indexForSlicingSeconds = seconds ? 5 : 8
            return (
                utcTimestamp
                    .split('T')
                    .join(' ')
                    .substring(
                        0,
                        utcTimestamp.split('T').join(' ').length - indexForSlicingSeconds,
                    ) + 'Z'
            )

        // CLIENT    
        } else if (timezone === 'client') {
            //console.log("utcTimestamp", utcTimestamp, new Date(utcTimestamp).toLocaleString())
            return new Date(utcTimestamp).toLocaleString()

        // CDT    
        } else if (timezone === 'cdt') {
            return (
                new Date(utcTimestamp).toLocaleString('en-US', {
                    timeZone: 'America/Chicago',
                }) + ' CDT'
            )
        }
    } catch {
        return new Date('1970-01-01T00:00:00')
    }
}


export const getRelativeTimeFromDateTime = (utcTimestamp) => {    
    return Math.ceil((new Date(utcTimestamp) - new Date()) / (1000 * 60 * 60))
}

export const getUtcTimestampFromOnlyNumbersTimestamp = (onlyNumberTimestamp) => {
    // Months are 0-11 (!)
    return new Date(
        Date.UTC(
            onlyNumberTimestamp.slice(0, 4),
            onlyNumberTimestamp.slice(4, 6) - 1,
            onlyNumberTimestamp.slice(6, 8),
            onlyNumberTimestamp.slice(8, 10),
            onlyNumberTimestamp.slice(10, 12),
        ),
    ).toISOString()
}

export const getDayOfTheWeekCdt = (utcTimestamp, dayFormat='long', today=true) => {
    if (today && (new Date(utcTimestamp).toLocaleString('en-US', {  timeZone: 'America/Chicago', weekday: dayFormat}) ===  new Date().toLocaleString('en-US', {  timeZone: 'America/Chicago', weekday: dayFormat}))) {
            return 'Today'        
    } else {
        return new Date(utcTimestamp).toLocaleString('en-US', {  timeZone: 'America/Chicago', weekday: dayFormat })
    }
    
}

export const reformatReportAtToUtc = (spotterReportAtTimeStamp) => {
    return new Date(spotterReportAtTimeStamp.replace(' ', 'T') + 'Z')
}

export const customTimestampTransform = (formatType, sliderValue) => {
    const now = new Date();
    const utcTimestamp = new Date(now.getTime() - (-parseInt(sliderValue) * 60 * 60 * 1000));
    let customTimestamp
    if (formatType === 'iastate') {
        let minutes = ('0' + utcTimestamp.getUTCMinutes()).slice(-2)
        let minuteModulo5 = minutes - (minutes % 5)
        customTimestamp =
            utcTimestamp.getUTCFullYear() +
            ('0' + (utcTimestamp.getUTCMonth() + 1)).slice(-2) +
            ('0' + utcTimestamp.getUTCDate()).slice(-2) +
            ('0' + utcTimestamp.getUTCHours()).slice(-2) +
            minuteModulo5
    } else if (formatType === 'realearth') {
        customTimestamp =
            utcTimestamp.getUTCFullYear() +
            ('0' + (utcTimestamp.getUTCMonth() + 1)).slice(-2) +
            ('0' + utcTimestamp.getUTCDate()).slice(-2) +
            '_' +
            ('0' + utcTimestamp.getUTCHours()).slice(-2) +
            '0000'
    }
    return customTimestamp
}

export const relativeTimeToString = (relativeTime) => {
    if (relativeTime < 0) {
        return String(relativeTime * -1) + "h ago"
    } else if (relativeTime === 0) {
        return "Now"    
    } else {
        return String(relativeTime) + "h from now"
    }
}

export const timeSince = (timestamp) => {
    try {
        const utcTimestamp = new Date(timestamp).toISOString()

        let seconds = Math.floor((new Date() - Date.parse(utcTimestamp)) / 1000)
        let interval = seconds / 31536000

        if (interval > 1) {
            return Math.floor(interval) + ' years'
        }
        interval = seconds / 2592000
        if (interval > 1) {
            return Math.floor(interval) + ' months'
        }
        interval = seconds / 86400
        if (interval > 1) {
            return Math.floor(interval) + ' days'
        }
        interval = seconds / 3600
        if (interval > 1) {
            return Math.floor(interval) + ' hours'
        }
        interval = seconds / 60
        if (interval > 1) {
            return Math.floor(interval) + ' minutes'
        }
        return Math.floor(seconds) + ' seconds'
    } catch {
        return -1
    }
}

export const decimalHourConvert = (hours) => {
    let rhours = Math.floor(hours)
    let minutes = (hours - rhours) * 60
    let rminutes = Math.round(minutes)
    return rhours + 'h ' + rminutes + 'min'
}

export const sortJSON = (arr, key, way) => {
    return arr.sort(function (a, b) {
        let x = a[key]
        let y = b[key]
        if (way === 'asc') {
            return x < y ? -1 : x > y ? 1 : 0
        }
        if (way === 'desc') {
            return x > y ? -1 : x < y ? 1 : 0
        }
    })
}

export const extractUsernameFromGivenTwitterName = (given_twitter_name) => {
    return given_twitter_name
        .split('/')
        .pop()
        .replace('@', '')
        .split(' ')
        .join('')
        .split('?')[0]
}

export const calculateUserTimeFromNow = (hourDifference) => {
    return new Date(Date.now() + hourDifference * 60 * 60 * 1000)
      .toLocaleString()
      .split(" ")[1]
      .split(":")
      .slice(0, 2)
      .join(":");
  }

export const calculateIsoTimeFromSlider = (relativeSliderValue) => {
    const nowMilliseconds = Date.now()
    const timeDifferenceInMilliseconds = relativeSliderValue * 3600000
    const relativeZuluTime = new Date(nowMilliseconds + timeDifferenceInMilliseconds)
    return relativeZuluTime.toISOString()
}

export const calculateZuluTimeFromNow = (hourDifference) => {
    // Get the current time
    const nowMilliseconds = Date.now()
    const timeDifferenceInMilliseconds = hourDifference * 3600000
    const relativeZuluTime = new Date(nowMilliseconds + timeDifferenceInMilliseconds)

    // Extract the hours and minutes from the adjusted time
    const hours = String(relativeZuluTime.getUTCHours()).padStart(2, '0');
    const minutes = String(relativeZuluTime.getUTCMinutes()).padStart(2, '0');

    // Return the time in the desired format
    return `${hours}:${minutes}`;

}

export const calculateCDTTimeFromNow = (hourDifference) => {
    // Create a new Date object for the current time
    const nowMilliseconds = Date.now()
    const timeDifferenceInMilliseconds = hourDifference * 3600000
    const relativeCdtTime = new Date(nowMilliseconds + timeDifferenceInMilliseconds).toLocaleString('en-US', {
        timeZone: 'America/Chicago',
        hour: '2-digit', minute:'2-digit'
    })
    return relativeCdtTime 
}

export const calculateRelativeTimeFromNow = (hourDifference) => {
    const timeDiff = Math.abs(hourDifference) // Remove minus through Math.abs

    let hours = parseInt(timeDiff * 10 / 10)
    let minutes = parseInt(timeDiff * 10 % 10) * 6
    
    // Check if hours is 0
    if (hours === 0) {
        return `-${minutes > 9 ? `${minutes}min` : `0${minutes}min`}`;
    } else {
        return `-${hours > 9 ? hours : `0${hours}`}h ${minutes > 9 ? `${minutes}min` : minutes ? `0${minutes}min` : ''}`;
    }            
}

export const capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string') return ''
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatTime = (time)=> {
    // Extract hours and minutes from the time string
    const hours = time.substring(0, 2);
    const minutes = time.substring(2);

    // Format the time with leading zeros if needed
    const formattedTime = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')} Z`;

    return formattedTime;
}

export const formatDate = (inputDate) => {
    const dateObj = new Date(inputDate);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  
  export const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  
  export const getFromDate = (totalMonth = 12) => {
    const currentDate = new Date();
    const date = new Date();
    date.setMonth(currentDate.getMonth() - totalMonth);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };


export const getHeatmapColor = (value, maxValue=500) => {
    // Ensure the value is within the range [0, 500]
    value = Math.min(maxValue, Math.max(0, value));

    // Calculate the ratio
    const ratio = value / maxValue;

    // Calculate the RGB components based on the ratio
    const red = Math.round(255 * ratio);
    const green = 255 - red;
    const blue = 0;

    // Create the CSS background property
    const backgroundColor = `rgba(${red}, ${green}, ${blue}, ${ratio + 0.1})`;
    
    if (!value) {
        return `rgb(255,255,255)`
    } else {
        return backgroundColor;
    }
}; 

export const hexToRgb = (hex, opacity=255, format='array') => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    if (format === 'array') {
        return [r, g, b, opacity];
    } else {
        return `rgba(${r}, ${g}, ${b}, ${opacity})`; // Used by outlook gradient
    }
    
}