
import {Helmet} from "react-helmet";
import React, { useState, useEffect } from "react";

import OutlookService from "services/OutlookService";
import ArticleTemplate from 'components/ArticleTemplate';
import {getRelativeTimeFromDateTime, getUtcTimestampFromOnlyNumbersTimestamp,relativeTimeToString} from "utils/general-functions";


const Forecast = () => {
    const outlookService = new OutlookService();
    const [outlookData, setOutlookData] = useState(null);
    const [forecastDate, setForecastDate] = useState("")
    const [doesAiForecastExist, setDoesAiForecastExist] = useState(false)
    //console.log(outlookData)


    useEffect(() => {
        async function fetchOutlooks() {
            try {
            const data = await outlookService.getSpcOutlooks();
            // Step 2: Set state with fetched data
            const todayData = data[1]
            setOutlookData(todayData);

            let validStartDate = new Date(getUtcTimestampFromOnlyNumbersTimestamp(todayData?.categorical[1]?.properties.VALID)).toLocaleString('en-US', {
                timeZone: 'America/Chicago',
            })

            if (todayData.outlook_summary["summary_headline"] !== "") {
                setDoesAiForecastExist(true)
            }
            //let expire = new Date(getUtcTimestampFromOnlyNumbersTimestamp(todayData.categorical[1].properties.EXPIRE))
            //    console.log("AVG",avgTimestamp)
            //const avgTimestamp = (valid.getTime() + expire.getTime()) / 2;            

            setForecastDate(new Date(validStartDate).toISOString().slice(0, 10))
            } catch (error) {
                console.error("Error fetching outlooks", error)
            }
        }
    
        fetchOutlooks();
      }, []);


    return (
        
        <ArticleTemplate headline={`Tornado/storm chasing forecast for ${forecastDate} `} >
            {
                // - from ${relativeTimeToString(getRelativeTimeFromDateTime(getUtcTimestampFromOnlyNumbersTimestamp(outlookData.categorical[1].properties.VALID)))} to ${ relativeTimeToString(getRelativeTimeFromDateTime(getUtcTimestampFromOnlyNumbersTimestamp(outlookData.categorical[1].properties.EXPIRE)))}
            }
            <Helmet>
                <title>Tornado/storm chasing forecast for {forecastDate}?</title>
                <meta name="description" content="Read up on the chances of storm chasing today" />
            </Helmet>   
            {outlookData && outlookData.categorical[0] ?
                <>
                
                <p className="text-sm">This forecast tool is based on SPC Outlooks. All sections marked with 🤖 are AI-generated and could be false/hallucinated! The forecast is valid from { relativeTimeToString(getRelativeTimeFromDateTime(getUtcTimestampFromOnlyNumbersTimestamp(outlookData.categorical[0]?.properties.VALID)))} to { relativeTimeToString(getRelativeTimeFromDateTime(getUtcTimestampFromOnlyNumbersTimestamp(outlookData.categorical[0]?.properties.EXPIRE)))}.</p>

                {doesAiForecastExist ? "" : 
                    <div class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                        <span class="font-medium">🤖 No AI-generated forecast available at the moment</span> The AI generated forecast summaries will be updated soon.
                    </div>                    
                }

                {doesAiForecastExist && (
                    <>
                        <h2>🤖 {outlookData.outlook_summary["summary_headline"]}</h2>
                        <p>{outlookData.outlook_summary["summary_intro"]}</p>
                        <p>Possibly affected cities: {outlookData.affected_cities === "" ? "None mentioned" : outlookData.affected_cities }</p>


                        <h2>🤖 Major risks</h2>
                        { outlookData?.outlook_summary?.major_risks ? (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: outlookData?.outlook_summary?.major_risks?.replaceAll("\n", "<br />- ").replaceAll("**", "")
                                }}
                                ></p> 
                        ) : (<p>No major risks today.</p>)}

               
                        <h2>🤖 Stormchasing opportunities</h2>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: outlookData?.outlook_summary?.stormchasing_possibilities?.replaceAll("**", "")
                            }}
                        ></p>     
                    </>
                )}                                            
                <div className="grid grid-cols-2">
                    <div>
                        <h2>Severe weather risk: {outlookData.max_categorical}</h2>                    
                        <a href={`https://www.spc.noaa.gov/products/outlook/archive/${String( outlookData?.categorical[0]?.properties.VALID.slice(0,8)).slice(0, 4)}/day1otlk_${String( outlookData.categorical[0].properties.VALID.slice(0,8))}_${outlookData.categorical[0].properties.VALID.slice(8,12)}.html`}
                    target="_blank" rel="noopener noreferrer">
                            <img
                            src={`https://www.spc.noaa.gov/products/outlook/archive/${String( outlookData?.categorical[0]?.properties.VALID.slice(0,8)).slice(0, 4)}/day1otlk_${String( outlookData.categorical[0].properties.VALID.slice(0,8))}_${outlookData.categorical[0].properties.VALID.slice(8,12)}_prt.gif`}
                            alt="" className={'w-[70%] object-cover m-auto'}
                            />
                        </a>   
                        <h2>🤖 Weather synopsis</h2>                
                            { outlookData.outlook_summary.weather_setup && outlookData.outlook_summary.weather_setup !== ''  ? (
                                <>                    
                                    {Object.entries(outlookData?.outlook_summary?.weather_setup).map(([key, value]) => (
                                        <>
                                        <h5>{value?.name}</h5>
                                        <p className="text-base">{value?.synopsis}</p>
                                        <ul className="text-base text-white">
                                            <li><span className="text-base text-bold">Major risks:</span> {value?.major_risks}</li>
                                            <li><span className="text-base">Triggering factors:</span> {value?.triggering_factors}</li>
                                            <li><span className="text-base">Inhibiting factors:</span> {value?.inhibiting_factors}</li>
                                            <li><span className="text-base">Initiation and peak:</span> {value?.initiation_and_peak}</li>
                                            <li><span className="text-base">Dewpoints</span>: {value?.dewpoints}</li>
                                            <li><span className="text-base">Max CAPE:</span> {value?.max_cape}</li>
                                            <li><span className="text-base">Possible wind speeds:</span> {value?.max_wind_speed}</li>
                                            <li><span className="text-base">Possible hail sizes:</span> {value?.max_hail}</li>
                                        </ul>                                
                                        </>
                                    ))}
                                </>
                            ) : (
                                <p>No weather synopsis exist for this date (at the moment).</p>
                            )}                           
                    </div>
                    <div>
                        <h2>Tornado risk: {outlookData.max_tornado === "0" ? "Less than 2" : outlookData.max_tornado}%</h2>
                        <a href={`https://www.spc.noaa.gov/products/outlook/archive/${String( outlookData?.categorical[0]?.properties.VALID.slice(0,8)).slice(0, 4)}/day1probotlk_${String( outlookData.categorical[0]?.properties.VALID.slice(0,8))}_${outlookData.categorical[0]?.properties.VALID.slice(8,12)}.html`}
                    target="_blank" rel="noopener noreferrer">
                            <img
                            src={`https://www.spc.noaa.gov/products/outlook/archive/${String( outlookData?.categorical[0]?.properties.VALID.slice(0,8)).slice(0, 4)}/day1probotlk_${String( outlookData.categorical[0]?.properties.VALID.slice(0,8))}_${outlookData.categorical[0]?.properties.VALID.slice(8,12)}_torn_prt.gif`}
                            alt="" className={'w-[70%] object-cover m-auto'}
                            />
                        </a> 
                        { doesAiForecastExist && outlookData.outlook_summary.tornado_risk ? (
                        <div className="grid grid-cols-5 gap-2 text-lg">
                            <div className="col-span-2">
                                🤖 Tornado risk
                            </div>
                            <div className="col-span-3">
                                {outlookData.outlook_summary.tornado_risk.risk}
                            </div>

                            <div className="col-span-2">
                                🤖 Where
                            </div>
                            <div className="col-span-3">
                                {outlookData.outlook_summary.tornado_risk.where}
                            </div>                            

                            <div className="col-span-2">
                                🤖 Anticipated strength
                            </div>
                            <div className="col-span-3">
                                {outlookData.outlook_summary.tornado_risk.anticipated_strength  ?? "Not specified"}
                            </div>

                            <div className="col-span-2">
                            🤖 Inhibiting factors
                            </div>
                            <div className="col-span-3">
                                {outlookData.outlook_summary.tornado_risk.tornado_inhibiting_factors  ?? "Unknown"}
                            </div> 
                        </div>             
                        ) : (
                            <p>No summarized tornado risk data exist for this date (at the moment).</p>
                        )
                    }

                    </div>   
                </div>                                

                <hr />
                <h2>SPC Outlook Text</h2>
                {outlookData ? (
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                        <h3>Original SPC Outlook text</h3>
                        <p>🔊 <a href="/forecast/text" className="underline">SPC Outlook text for text-to-speech without abbreviations.</a></p>
                        <p
                        dangerouslySetInnerHTML={{
                            __html: outlookData?.outlook_text?.replaceAll("\n", "<br/>"),
                        }}
                        ></p>
                    </div>
                    <div>
                        <h3>🤖 Simplified SPC Outlook text</h3>
                        { doesAiForecastExist ?                        
                        <p
                            dangerouslySetInnerHTML={{
                                __html: outlookData?.outlook_summary?.simplified_outlook_text?.replaceAll("\n", "<br />").replaceAll("...", "").replaceAll(":**", "</h3>").replaceAll("**", "<h3>").replaceAll("tornadoes", "TORNADOES 🌪️🌪️").replaceAll("tornado", "TORNADO 🌪️").replaceAll("supercells", "SUPERCELLS").replaceAll("supercell", "SUPERCELL")
                            }}
                            >
                        </p> : 
                        <p>No simplified forecast available at the moment</p>}
                    </div>   
                                     
                  </div>
                ) : (
                  <p>No outlook text exist for this date (at the moment).</p>
                )}                
                </>
                : <div>Loading forecasting data...</div>
            }


            </ArticleTemplate>
    );
};

export default Forecast;
